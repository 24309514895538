import Api from '../../config/Api/API';

const state = {};

const getters = {};
const mutations = {};
const actions = {
  async genericPost(_, {
    route, body, responseType, acceptHeader,
  }) {
    const res = await Api.post({
      path: route, payload: body, responseType, acceptHeader,
    });
    if (res.successful) return res.response.data;
    return res.error.response;
  },

  async getAnimalDistributionSetups() {
    const res = await Api.get({ path: 'api/animalDistribution/setup' });
    if (!res.successful) return null;
    return res.response.data;
  },
  async loadExpectedAnimalDistribution(_, setupId) {
    const res = await Api.get({ path: `api/animalDistribution/${setupId}` });
    if (!res.successful) return null;
    return res.response.data;
  },
  async loadAnimalDistribution(_, { setupId, duration, timeResolution }) {
    const res = await Api.post({
      path: `api/animalDistribution/${setupId}`,
      payload: {
        time: { duration },
        timeResolution,
      },
    });
    if (!res.successful) return null;
    return res.response.data;
  },
  async loadAnimalCountForSingleCamera(_, cameraId) {
    const timespan = '1d';
    const query = {
      columns: { count: 'max' },
      time: {
        duration: timespan,
      },
      where: {
        label: ['pig_lying', 'pig_sitting', 'pig_standing', 'Broiler'],
      },
    };
    if (cameraId) {
      query.where.camera = cameraId;
    }
    const res = await Api.post({
      path: 'api/data/labelCount',
      payload: {
        iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
        query: [query],
      },
    });
    if (!res.successful) return false;
    return res.response.data;
  },
  async getCurrentNightVisionStatusForSingleCamera(_, cameraId) {
    const res = await Api.post({
      path: 'api/data/nightvision',
      payload: {
        iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
        query: {
          columns: { nightvision: 'last' },
          time: {
            duration: '1h',
          },
          where: { camera: cameraId },
        },
      },
    });
    if (!res.successful || res.response.data.length !== 1) return false;
    return res.response.data[0].last_nightvision;
  },

  async fetchUsedLabels() {
    try {
      const endpoint = 'api/data/labels';
      const response = await Api.get({ path: endpoint, responseType: 'json' });
      return response;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
