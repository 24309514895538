<template>
  <vet-modal :showCancel="showCancel">
    <div
      class="inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all sm:my-4 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:mt-0 sm:ml-4 lg:text-left">
          <h3
            class="text-lg leading-6 font-medium text-gray-100"
            id="modal-headline"
          >
            <slot name="header"></slot>
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-300">
              <slot name="body"></slot>
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="status === 'Error'"
        class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
      >
        <div class="lg:flex-row-reverse lg:flex" dark>
          <div>
            <v-btn
              large
              @click="$emit('output', true)"
              class="lg:w-auto w-full mb-3"
              color="error"
            >
              {{ $t("button.delete") }}
            </v-btn>
          </div>
          <div>
            <v-btn
              @click="$emit('output', false)"
              class="border-gray-600 border lg:mr-3 lg:w-auto w-full"
              depressed
              large
              outlined
              dark
            >
              {{ $t("button.cancel") }}
            </v-btn>
          </div>
        </div>
      </div>
      <div v-else class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <div>
          <v-btn
            large
            @click="$emit('accept', true)"
            class="lg:w-auto w-full mb-3"
            color="primary"
          >
            {{ $t("button.confirm") }}
          </v-btn>
        </div>
        <div>
          <v-btn
            @click="$router.push({name: 'SelectBarn'})"
            class="border-gray-600 border lg:mr-3 lg:w-auto w-full"
            depressed
            large
            outlined
            dark
          >
            {{ $t("select_barn.title") }}
          </v-btn>
        </div>
      </div>
    </div>
  </vet-modal>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'Error',
    },
    showCancel: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
