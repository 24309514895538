const humanizeDuration = require('humanize-duration');
const version = require('@/version').default;

const getDefaultState = () => ({
  developerOptions: {
    showBarnImages: false,
    showBarnVersions: false,
    displayCameraIdsInGridView: false,
    annotateStillFrames: false,
    disableMediaLoading: false,
    enableGenericInstances: false,
    enableSingleSignOn: false,
    simulatedPermissions: {
      enable: false,
      permissions: [],
    },
  },
  lang: 'en',
  languageSet: false,
  languages: {
    en: {
      name: 'English',
      file: '',
      flagId: 'gb',
    },
    de: {
      name: 'Deutsch',
      file: '',
      flagId: 'de',
    },
  },
  graphSettings: {
    durations: [
      1000 * 60 * 60, // last hour
      1000 * 60 * 60 * 24, // last day
      1000 * 60 * 60 * 24 * 7, // last week
      1000 * 60 * 60 * 24 * 7 * 4, // last month
    ],
  },
  developerMode: false,
  disableMediaLoading: false,
  animalDistributionFactor: 1,
  frameTimeIntervals: [
    1000, // 1 second
    1000 * 10, // 10 seconds
    1000 * 60, // 1 minute
    1000 * 60 * 5, // 5 minutes
    1000 * 60 * 10, // 10 Minutes
    1000 * 60 * 30, // 30 Minutes
    1000 * 60 * 60, // 1 hour
    1000 * 60 * 60 * 6, // 6 hours
    1000 * 60 * 60 * 12, // 12 hours
    1000 * 60 * 60 * 24, // 24 hours
  ],
  annotateStillFrames: false,
  loadBarnImages: false,
  showBarnVersions: false,
  showNoTagCameras: false,
});
const state = getDefaultState();

const getters = {
  getDeveloperOptions: (state) => state.developerOptions,
  getAnimalDistributionFactor: (state) => state.animalDistributionFactor,
  graphDurations: (state) => state.graphSettings.durations.map((d) => ({ value: d, name: humanizeDuration(d, state.lang) })),
  frameTimeIntervals: (state) => state.frameTimeIntervals.map((interval) => ({ value: interval, name: humanizeDuration(interval, state.lang) })),
  version: () => version,
  languages: (state) => state.languages,
  getLang: (state) => state.lang,
  isLanguageSet: (state) => state.languageSet,
  developerMode: (state) => state.developerMode,
  alsoShowNoTagCameras: (state) => state.showNoTagCameras,
};
const mutations = {
  setAnimalDistributionFactor(state, factor) {
    state.animalDistributionFactor = factor;
  },
  setDeveloperMode(state, mode) {
    state.developerMode = mode;
    if (mode === false) {
      state.graphSettings.durations = getDefaultState().graphSettings.durations;
      state.animalDistributionFactor = getDefaultState().animalDistributionFactor;
      state.disableMediaLoading = getDefaultState().disableMediaLoading;
    }
  },
  setLang(state, lang) {
    state.languageSet = true;
    state.lang = lang;
  },
  setGraphDurations(state, data) {
    data.sort((a, b) => a - b);
    state.graphSettings.durations = data;
  },
  resetGraphDurations(state) {
    state.graphSettings.durations = getDefaultState().graphSettings.durations;
  },
  setShowNoTagCameras(state, data) {
    state.showNoTagCameras = data;
  },
  saveDeveloperOptions(state, data) {
    state.developerOptions = data;
  },
};
const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
