<template>
        <v-btn icon @click="$router.go(-1)" small dark rounded v-if="visible">
          <v-icon dark :small="$vuetify.breakpoint.xs">arrow_back</v-icon>
        </v-btn>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  name: 'BackButton',
};
</script>
<style>
</style>
