/* eslint-disable no-await-in-loop */
// eslint-disable-next-line import/no-cycle
import Api from '../../config/Api/API';

function convertHexToShortHex(hex) {
  return hex.substr(0, 7);
}

const state = {
  regionTypes: {},
  regionSpecifications: {},
  regionMappings: {},
};

const getters = {
  regionCountForCamera: (state, getters) => (cameraId) => {
    const mappings = getters.regionMappingsForCamera(cameraId);
    let count = 0;
    Object.values(mappings).forEach((mapping) => {
      count += mapping.changed.regions.length;
    });
    return count;
  },
  colorOfPolygon: (state, getters) => (regionMappingId, index) => {
    const mapping = getters.specificRegionMapping(regionMappingId);
    const region = mapping.changed.regions[index];
    if (region.color) return region.color;

    return getters.getColorOfRegionSpecification(region.regionSpecification);
  },
  getColorOfRegionSpecification: (state, getters) => (id) => {
    const specification = getters.specificRegionSpecification(id);
    if (specification.color) return specification.color;
    return getters.getColorOfRegionType(specification.regionType);
  },
  getColorOfRegionType: (state, getters) => (id) => {
    const type = getters.specificRegionType(id);
    return type.color;
  },
  allRegionTypes: (state) => Object.fromEntries(
    Object.entries(state.regionTypes).map(([key, value]) => [
      key,
      Object.assign(value, { changedColor: value.color }),
    ]),
  ),
  specificRegionType: (state) => (id) => {
    if (!state.regionTypes || !state.regionTypes[id]) return {};
    return Object.assign(state.regionTypes[id], {
      changedColor: state.regionTypes[id].color,
    });
  },
  specificRegionSpecification: (state) => (id) => {
    if (!state.regionSpecifications || !state.regionSpecifications[id]) return {};
    return Object.assign(state.regionSpecifications[id], {
      changedColor: state.regionSpecifications[id].color,
      changedRegionType: state.regionSpecifications[id].regionType,
    });
  },
  allRegionSpecifications: (state) => {
    if (!state.regionSpecifications) return {};
    return Object.fromEntries(
      Object.entries(state.regionSpecifications).map(([key, value]) => [
        key,
        Object.assign(value, {
          changedColor: value.color,
          changedRegionType: value.regionType,
        }),
      ]),
    );
  },
  regionSpecificationsForType: (state, getters) => (type) => Object.entries(getters.allRegionSpecifications).filter(
    ([, specification]) => specification.regionType === type,
  ),
  allRegionMappings: (state) => {
    if (!state.regionMappings) return {};
    return Object.fromEntries(
      Object.entries(state.regionMappings).map(([key, value]) => [
        key,
        { original: value, changed: value },
      ]),
    );
  },
  regionMappingsForCamera: (state) => (cameraId) => {
    if (!state.regionMappings) return {};
    return Object.fromEntries(
      Object.entries(state.regionMappings)
        .filter(([, value]) => value.cameraId === cameraId)
        .map(([key, value]) => [key, { original: value, changed: value }]),
    );
  },
  findRegionMappingId:
    (state) => ({ cameraId, regionType }) => {
      if (!state.regionMappings) return null;
      let mappingId = null;
      Object.entries(state.regionMappings).forEach(([id, mapping]) => {
        if (
          mapping.changed.cameraId === cameraId
          && mapping.changed.regionType === regionType
        ) mappingId = id;
      });
      return mappingId;
    },
  specificRegionMapping: (state) => (id) => ({
    original: state.regionMappings[id],
    changed: state.regionMappings[id],
  }),
};
const mutations = {
  clearRegionTypes(state) {
    state.regionTypes = {};
  },
  setRegionTypes(state, data) {
    state.regionTypes = data;
  },
  setRegionType(state, data) {
    state.regionTypes = { ...state.regionTypes, [data.id]: data.regionType };
  },
  deleteRegionType(state, data) {
    state.regionTypes = Object.fromEntries(
      Object.entries(state.regionTypes).filter(([key]) => key !== data),
    );
  },
  clearRegionSpecifications(state) {
    state.regionSpecifications = {};
  },
  setRegionSpecifications(state, data) {
    state.regionSpecifications = data;
  },
  deleteRegionSpecification(state, data) {
    state.regionSpecifications = Object.fromEntries(
      Object.entries(state.regionSpecifications).filter(([key]) => key !== data),
    );
  },
  setRegionSpecification(state, data) {
    state.regionSpecifications = {
      ...state.regionSpecifications,
      [data.id]: data.regionSpecification,
    };
  },
  clearRegionMappings(state) {
    state.regionMappings = {};
  },
  setRegionMappings(state, data) {
    state.regionMappings = data;
  },
};
const actions = {
  async loadRegionTypes({ commit }) {
    commit('clearRegionTypes');
    const endpoint = 'api/regions/types';
    const { response } = await Api.get({ path: endpoint });
    commit('setRegionTypes', response.data.result);
    return response.data.result;
  },
  async saveRegionType({ commit }, { id, regionType }) {
    const endpoint = `api/regions/types/${id}`;
    await Api.put({
      path: endpoint,
      payload: {
        value: { color: convertHexToShortHex(regionType.changedColor) },
      },
    });
    commit('setRegionType', {
      id,
      regionType: { color: convertHexToShortHex(regionType.changedColor) },
    });
  },
  async saveRegionSpecification({ commit }, { id, regionSpecification }) {
    const endpoint = `api/regions/specifications/${id}`;
    await Api.put({ path: endpoint, payload: { value: regionSpecification } });
    commit('setRegionSpecification', {
      id,
      regionSpecification,
    });
  },
  async createNewRegionType({ commit }, { id, regionType }) {
    const endpoint = `api/regions/types/${id}`;
    await Api.put({
      path: endpoint,
      payload: {
        value: { color: convertHexToShortHex(regionType.color) },
      },
    });
    commit('setRegionType', {
      id,
      regionType: { color: convertHexToShortHex(regionType.color) },
    });
  },
  async deleteRegionType({ commit }, id) {
    const endpoint = `api/regions/types/${id}`;
    await Api.delete({ path: endpoint });
    commit('deleteRegionType', id);
  },
  async loadRegionSpecifications({ commit }) {
    commit('clearRegionSpecifications');
    const endpoint = 'api/regions/specifications';
    const { response } = await Api.get({ path: endpoint });
    commit('setRegionSpecifications', response.data.result);
    return response.data.result;
  },
  async deleteRegionSpecification({ commit }, id) {
    const endpoint = `api/regions/specifications/${id}`;
    await Api.delete({ path: endpoint });
    commit('deleteRegionSpecification', id);
  },
  async createNewRegionSpecification({ commit }, { id, regionSpecification }) {
    const endpoint = `api/regions/specifications/${id}`;
    await Api.put({
      path: endpoint,
      payload: {
        value: {
          color: convertHexToShortHex(regionSpecification.color),
          regionType: regionSpecification.regionType,
        },
      },
    });
    commit('setRegionSpecification', {
      id,
      regionSpecification: {
        color: convertHexToShortHex(regionSpecification.color),
        regionType: regionSpecification.regionType,
      },
    });
  },
  async loadRegionMappings({ commit }) {
    commit('clearRegionMappings');
    const { response } = await Api.get({ path: 'api/regions/mappings' });
    commit('setRegionMappings', response.data.result);
    return response.data.result;
  },
  async deleteRegionMapping({ commit }, { mapping, id }) {
    const mappingRecordResult = (
      await Api.get({
        path: `api/regions/mappings/${mapping.autoGeneratedMappingId}`,
      })
    ).response;
    const mappingRecord = mappingRecordResult.data.result;
    mappingRecord.regions = mappingRecord.regions.filter(
      (region) => region.regionSpecification !== mapping.regionSpecification,
    );
    await Api.put({
      path: `api/regions/mappings/${mapping.autoGeneratedMappingId}`,
      payload: mappingRecord,
    });
    commit('deleteRegionMapping', id);
  },
  async saveRegionMappings(_, regionMappings) {
    for (const [mappingId, mapping] of Object.entries(regionMappings)) {
      if (mapping.changed.isNew === true) {
        await Api.post({
          path: 'api/regions/mappings/',
          payload: {
            value: {
              cameraId: mapping.changed.cameraId,
              regionType: mapping.changed.regionType,
              regions: mapping.changed.regions,
            },
          },
        });
      } else {
        await Api.put({
          path: `api/regions/mappings/${mappingId}`,
          payload: {
            value: {
              cameraId: mapping.changed.cameraId,
              regionType: mapping.changed.regionType,
              regions: mapping.changed.regions,
            },
          },
        });
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
