// import Api from '../../config/Api/API';

// const logger = require('@/Logger');

const state = {
  connectionURL: 'https://senator.vms.dev.vetvise.com',
};

const getters = {
  getSenatorConnectionURL: (state) => state.connectionURL,
};
const mutations = {

};
const actions = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
