import FlagIcon from 'vue-flag-icon';
import Vue from 'vue';
import VueExcelXlsx from 'vue-excel-xlsx';
import sanitizeHtml from 'sanitize-html';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './utils/components';
import i18n from './i18n';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(FlagIcon);

Vue.use(VueExcelXlsx);
Vue.config.productionTip = false;
const logger = require('./Logger');

window.onerror = function (message, url, lineNumber, col, error) {
  logger.log('ERROR', `Caught Error ${message} at ${url} at ${lineNumber} at col ${col}: ${error}`);
  console.log(`Caught Error ${message} at ${url} at ${lineNumber} at col ${col}: ${error}`);
  return false;
};
window.addEventListener('unhandledrejection', (event) => {
  logger.log('ERROR', `Caught unhandled promise rejection ${event.reason}`);
  console.log(`Caught unhandled promise rejection ${event.reason}`);
});
store.dispatch('migrateBarnsToBackendInstances').then(() => logger.log('DEBUG', 'Migrated Barns to Backend Instances!'));
store.dispatch('ensureSenatorInstanceExists');
Vue.mixin({
  methods: {
    sanitizedHTML(content) {
      return sanitizeHtml(content, { allowedTags: ['b', 'i', 'em', 'strong', 'hr', 'p', 'ol', 'h1', 'h2', 'h3', 'h4', 'h6', 'li', 'ul'] });
    },
    humanReadableTimestamp(timestamp) {
      return new Date(timestamp).toLocaleString(this.$store.getters.getLang);
    },
    humanReadableFileSize(filesize) {
      const i = filesize === 0 ? 0 : Math.floor(Math.log(filesize) / Math.log(1024));
      return `${(filesize / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
    randomString(length = 20) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      // Pick characers randomly
      let str = '';
      for (let i = 0; i < length; i += 1) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return str;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
