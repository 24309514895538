// eslint-disable-next-line import/no-cycle
import { heatmap, longTimeExposure } from '@/store/artifacts/artifactEnricher';
import Api from '../../config/Api/API';

const logger = require('@/Logger');
// eslint-disable-next-line import/no-cycle

const getDefaultState = () => ({
  artifacts: {},
  artifactPayloads: {},
  artifactControlRecords: {},
});

const state = getDefaultState();

function enrichArtifact(artifact, state, getters, rootState, rootGetters) {
  if (artifact.type === 'heatmap') return heatmap(artifact, state, getters, rootState, rootGetters);
  if (artifact.type === 'longTimeExposure') return longTimeExposure(artifact, state, getters, rootState, rootGetters);
  return artifact;
}

const getters = {
  getArtifact: (state, getters, rootState, rootGetters) => (artifactId) => enrichArtifact(state.artifacts[artifactId], state, getters, rootState, rootGetters),
  getArtifactPayload: (state) => (artifactId) => state.artifactPayloads[artifactId],
  getArtifactControlRecord: (state) => (artifactId) => state.artifactControlRecords[artifactId],
};

const mutations = {
  addArtifact(state, artifact) {
    this._vm.$set(state.artifacts, artifact.artifactId, artifact);
    if (!state.artifactControlRecords[artifact.artifactId]) this._vm.$set(state.artifactControlRecords, artifact.artifactId, {});
  },
  ensureArtifactControlRecordExists(state, artifactId) {
    if (!state.artifactControlRecords[artifactId]) this._vm.$set(state.artifactControlRecords, artifactId, {});
  },
  addArtifactPayload(state, { artifactId, payload }) {
    this._vm.$set(state.artifactPayloads, artifactId, payload);
  },
  markAsDownloading(state, artifactId) {
    if (!state.artifactControlRecords[artifactId]) this._vm.$set(state.artifactControlRecords, artifactId, {});
    this._vm.$set(state.artifactControlRecords[artifactId], 'downloading', true);
  },
  markAsNotDownloading(state, artifactId) {
    if (!state.artifactControlRecords[artifactId]) this._vm.$set(state.artifactControlRecords, artifactId, {});
    if (Object.keys(state.artifactControlRecords).includes(artifactId)) this._vm.$set(state.artifactControlRecords[artifactId], 'downloading', false);
  },
  markAsCurrentlyDownloading(state, artifactId) {
    if (!state.artifactControlRecords[artifactId]) this._vm.$set(state.artifactControlRecords, artifactId, {});
    this._vm.$set(state.artifactControlRecords[artifactId], 'currentlyDownloading', true);
  },
  markAsDownloaded(state, artifactId) {
    if (!state.artifactControlRecords[artifactId]) this._vm.$set(state.artifactControlRecords, artifactId, {});
    this._vm.$set(state.artifactControlRecords, artifactId, { downloading: false, currentlyDownloading: false, downloaded: true });
  },
};

const actions = {

  async createHeatmap({ rootGetters }, {
    globalUniqueIdentifier = undefined, cameraId, width, time, colorMap, labels, gridSize, storeArtifact,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier !== undefined ? globalUniqueIdentifier : rootGetters.getSelectedBarnId;

    const result = await Api.post({
      path: 'api/artifact/create/heatmap',
      responseType: 'blob',
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      payload: {
        storeArtifact,
        data: {
          cameraId,
          width,
          time,
          colorMap,
          labels,
          settings: { gridsize: gridSize },
        },
      },
    });
    if (result.successful) return result.response;
    return false;
  },

  async loadArtifactList({ commit, getters }, {
    query, returnArtifactList = false, order = false,
  }) {
    try {
      const payload = { query };
      if (order !== false) payload.order = order;
      const result = await Api.post({ path: 'api/artifact/data', payload });
      if (!result.successful) return false;
      const artifacts = result.response.data;
      const artifactIds = [];
      artifacts.forEach((artifact) => {
        commit('addArtifact', artifact);
        artifactIds.push(artifact.artifactId);
      });
      if (returnArtifactList === true) {
        return artifactIds.map((artifactId) => getters.getArtifact(artifactId));
      } return artifactIds;
    } catch (e) {
      console.log(e);
      logger.log('Error', `Could not load Artifact List with query ${JSON.stringify(query)} because of ${e.stack}`);
    }
    return undefined;
  },
  async downloadArtifact({ commit, getters }, { artifactId, abortSignal = null }) {
    try {
      commit('ensureArtifactControlRecordExists', artifactId);
      if (getters.getArtifactControlRecord(artifactId).currentlyDownloading === true) return undefined;
      if (getters.getArtifactControlRecord(artifactId).downloaded === true && getters.getArtifactPayload(artifactId)) return undefined;
      commit('markAsDownloading', artifactId);
      commit('markAsCurrentlyDownloading', artifactId);
      if (abortSignal) {
        abortSignal.addEventListener('abort', () => {
          commit('markAsNotDownloading', artifactId);
        });
      }
      const endpoint = `api/artifact/${artifactId}`;
      const { response } = await Api.get({ path: endpoint, responseType: 'blob', signal: abortSignal });
      commit('addArtifactPayload', { artifactId, payload: URL.createObjectURL(response.data || null) });
      commit('markAsDownloaded', artifactId);
      return response;
    } catch (error) {
      commit('markAsNotDownloading', artifactId);
    } return undefined;
  },

  async recordingExport(_, {
    cameraId, start, end, signal, onDownloadProgress,
  }) {
    try {
      if (signal) {
        signal.addEventListener('abort', () => ({ successful: false, aborted: true }));
      }
      const endpoint = `api/recordings/file/${cameraId}/${start}/${end}`;
      const { response } = await Api.get({
        path: endpoint, responseType: 'blob', signal, onDownloadProgress, allowGZip: false,
      });
      return { successful: true, blob: response.data, aborted: false };
    } catch (e) {
      return { successful: false, aborted: false };
    }
  },

  async saveArtifact({ getters }, { artifactId }) {
    const artifact = getters.getArtifact(artifactId);
    if (!artifact || !getters.getArtifactControlRecord(artifactId).downloaded) return;
    const blob = getters.getArtifactPayload(artifactId);
    if (!blob) return;
    const link = document.createElement('a');
    link.href = blob;
    link.download = artifact.preferredFilename;
    link.click();
    link.remove();
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
