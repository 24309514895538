/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import Api from '../../config/Api/API';
// eslint-disable-next-line import/no-cycle
import router from '../../router';

const logger = require('@/Logger');

const getDefaultState = () => ({

});

const state = getDefaultState();

const getters = {};

const mutations = {};

const actions = {
  async fetchAllPermissions({ rootGetters }, { globalUniqueIdentifier = undefined } = {}) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: 'api/authorization/permissions/all', globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async listAllAccounts({ rootGetters }, { globalUniqueIdentifier = undefined } = {}) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: 'api/accounts', globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async listPermissionsForAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, recursive = false }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/authorization/permissions/by_user/${accountId}/?recursive=${recursive}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async listAllRoles({ rootGetters }, { globalUniqueIdentifier = undefined } = {}) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: 'api/authorization/roles/all', globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async listRolesForAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, recursive = false }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/authorization/roles/by_user/${accountId}/?recursive=${recursive}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async listInheritableRolesForAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/authorization/roles/available_for_user/${accountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async addRoleToAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, roleId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.put({ path: `api/authorization/add_role/${roleId}/to_user/${accountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },
  async removeRoleFromAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, roleId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.delete({ path: `api/authorization/delete_role/${roleId}/from_user/${accountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },

  async addPermissionToAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, permissionId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.put({ path: `api/authorization/add_permission/${permissionId}/to_user/${accountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },
  async removePermissionFromAccount({ rootGetters }, { globalUniqueIdentifier = undefined, accountId, permissionId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.delete({ path: `api/authorization/delete_permission/${permissionId}/from_user/${accountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
