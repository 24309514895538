<template>
  <v-container class="ma-0 pa-0">
    <BarnInterventionIncidentEventTriggerDialog
      v-on:triggerevent="triggerEvent"
      v-on:reload="reload"
      v-if="incident.type === 'barnIntervention'"
      :incident="incident"
    >
    </BarnInterventionIncidentEventTriggerDialog>
  </v-container>
</template>

<script>
import BarnInterventionIncidentEventTriggerDialog
  from '@/components/Incidents/SpecificIncidentEventTriggerDialogs/BarnInterventionIncidentEventTriggerDialog.vue';

export default {
  name: 'IncidentEventTriggerDialog',
  components: { BarnInterventionIncidentEventTriggerDialog },
  props: { incident: Object },
  methods: {
    triggerEvent(payload) {
      this.$emit('triggerevent', payload);
    },
    reload() {
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>

</style>
