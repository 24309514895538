const getDefaultState = () => ({
  authCache: {},
  authRecords: {},
  barns: {},
  selectedBarnId: null,
  userLists: {},
  systemStats: {},
  recordingStatus: {},
});

const state = getDefaultState();

const getters = {
  getAllBarnsForMigration: (state) => state.barns,
  getAllAuthRecordsForMigration: (state) => state.authRecords,
};
const mutations = {
  markBarnAsMigrated(state, barnId) {
    this._vm.$set(state.barns[barnId], 'migrated', true);
  },

};
const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
