// import Api from "../../config/Api/api";

const getDefaultState = () => ({});

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
