/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import Api from '../../config/Api/API';
// eslint-disable-next-line import/no-cycle
import router from '../../router';

const logger = require('@/Logger');
const { Buffer } = require('buffer');

async function testConnectivity(urlToTest) {
  if (!urlToTest) return [false, '', 'Strategy not used!'];
  try {
    const res = await fetch(`${urlToTest}api/p`, { method: 'GET' });
    if (res.ok) {
      return [true, urlToTest, ''];
    }
    return [
      false,
      urlToTest,
      `Server did not return the expected Result! ${res.status}`,
    ];
  } catch (e) {
    return [false, urlToTest, e.message];
  }
}

function buildRemoteUrl({ remote }) {
  if (!remote) return false;
  return new URL(`${remote.protocol}://${remote.hostname}:${remote.port}`);
}

function buildGatewayUrl({ gateway }) {
  if (!gateway) return false;
  return new URL(`${gateway.protocol}://${gateway.hostname}:${gateway.port}/gw/${gateway.id}/`);
}

function buildLocalUrl({ local }) {
  if (!local) return false;
  return new URL(`${local.protocol}://${local.hostname}:${local.port}`);
}

const getDefaultState = () => ({
  backendInstances: {},
  lastConnectionCheckTimestamps: {},
  lastCompleteConnectionCheckTimestamps: {},
  selectedBarnId: null,
});

const state = getDefaultState();

const getters = {
  getAccessToken: (state) => (globalUniqueIdentifier) => state?.backendInstances[globalUniqueIdentifier]?.accessToken,

  getAccountId: (state) => (globalUniqueIdentifier) => state?.backendInstances[globalUniqueIdentifier]?.accountId,

  getAccountIdForSelectedBarn: (state, getters) => getters.getAccountId(state.selectedBarnId),

  getBackendInstance: (state) => (globalUniqueIdentifier) => state.backendInstances[globalUniqueIdentifier],

  getBackendInstances: (state) => state.backendInstances,

  getBackendInstanceImageUrl: (state) => (globalUniqueIdentifier) => {
    if (state.backendInstances[globalUniqueIdentifier]?.imageUrl) return state.backendInstances[globalUniqueIdentifier]?.imageUrl;
    return 'https://storage.googleapis.com/vetvise.appspot.com/public_assets/vetvise-background.png';
  },

  isLoggedInIntoInstance: (state) => (globalUniqueIdentifier) => state.backendInstances[globalUniqueIdentifier]?.loggedIn === true,

  getBarns: (state) => Object.fromEntries(Object.entries(state.backendInstances).filter(([_, instance]) => instance && instance.type === 'barn')),

  getSenator: (state) => {
    if (!Object.entries(state.backendInstances).find(([, instance]) => instance.type === 'senator')) return null;
    return Object.entries(state.backendInstances).find(([, instance]) => instance.type === 'senator')[1];
  },

  getEulaStatus: (state) => (globalUniqueIdentifier) => state.backendInstances[globalUniqueIdentifier] && state.backendInstances[globalUniqueIdentifier].eulaNeeded === true,

  getBackendInstanceUrlToUse: (state, getters) => (globalUniqueIdentifier) => {
    const connectionStrategy = getters.getBackendInstanceActiveConnectionStrategy(globalUniqueIdentifier);
    if (!connectionStrategy) return null;
    const { discoveryObject } = state.backendInstances[globalUniqueIdentifier];

    if (connectionStrategy === 'local') return buildLocalUrl(discoveryObject);
    if (connectionStrategy === 'remote') return buildRemoteUrl(discoveryObject);
    if (connectionStrategy === 'gateway') return buildGatewayUrl(discoveryObject);
    return null;
  },

  getBackendInstanceActiveConnectionStrategy: (state) => (globalUniqueIdentifier) => {
    if (!state.backendInstances || !state.backendInstances[globalUniqueIdentifier] || !state.backendInstances[globalUniqueIdentifier].activeConnectionStrategy) return null;
    return state.backendInstances[globalUniqueIdentifier].activeConnectionStrategy;
  },

  getLastConnectionCheck: (state) => (globalUniqueIdentifier) => {
    if (!state.lastConnectionCheckTimestamps || !state.lastConnectionCheckTimestamps[globalUniqueIdentifier]) return null;
    return state.lastConnectionCheckTimestamps[globalUniqueIdentifier];
  },

  getLastCompleteConnectionCheck: (state) => (globalUniqueIdentifier) => {
    if (!state.lastCompleteConnectionCheckTimestamps || !state.lastCompleteConnectionCheckTimestamps[globalUniqueIdentifier]) return null;
    return state.lastCompleteConnectionCheckTimestamps[globalUniqueIdentifier];
  },

  getDiscoveryObject: (state) => (globalUniqueIdentifier) => state?.backendInstances[globalUniqueIdentifier]?.discoveryObject,

  getSelectedBarnId: (state) => state.selectedBarnId,

  getSelectedBarn: (state) => state.backendInstances[state.selectedBarnId],

  getSelectedBarnUrl: (state, getters) => getters.getBackendInstanceUrlToUse(state.selectedBarnId),

  hasAllPermissions: (state) => (payload) => {
    let permissionsToUse;
    let guidToUse = state.selectedBarnId;
    if (typeof payload === 'object') {
      permissionsToUse = typeof payload.permissions === 'string' ? [payload.permissions] : payload.permissions;
      guidToUse = payload.globalUniqueIdentifier !== undefined ? payload.globalUniqueIdentifier : state.selectedBarnId;
    } else if (typeof payload === 'string') {
      permissionsToUse = [payload];
    } else if (Array.isArray(payload)) {
      permissionsToUse = payload;
    }
    if (!permissionsToUse) return false;
    if (!guidToUse) return false;
    if (!state.backendInstances[guidToUse]) return false;
    return permissionsToUse.every((permission) => state.backendInstances[guidToUse].availablePermissions.includes(permission));
  },

  hasAnyPermission: (state) => (payload) => {
    let permissionsToUse;
    let guidToUse = state.selectedBarnId;
    if (typeof payload === 'object') {
      permissionsToUse = typeof payload.permissions === 'string' ? [payload.permissions] : payload.permissions;
      guidToUse = payload.globalUniqueIdentifier !== undefined ? payload.globalUniqueIdentifier : state.selectedBarnId;
    } else if (typeof payload === 'string') {
      permissionsToUse = [payload];
    } else if (Array.isArray(payload)) {
      permissionsToUse = payload;
    }
    if (!permissionsToUse) return false;
    if (!guidToUse) return false;
    if (!state.backendInstances[guidToUse]) return false;
    return permissionsToUse.some((permission) => state.backendInstances[guidToUse].availablePermissions.includes(permission));
  },
};

const mutations = {

  markEulaAsNeeded(state, globalUniqueIdentifier) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'eulaNeeded', true);
  },

  markEulaAsResolved(state, globalUniqueIdentifier) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'eulaNeeded', false);
  },

  setAccessToken(state, { globalUniqueIdentifier, token }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'accessToken', token);
  },

  setOwnPermissions(state, { globalUniqueIdentifier, permissions }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'availablePermissions', permissions);
  },

  addNewBackendInstance(state, backendInstance) {
    this._vm.$set(state.backendInstances, backendInstance.globalUniqueIdentifier, backendInstance);
  },

  changeInstanceName(state, { globalUniqueIdentifier, name }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'name', name);
  },
  changeInstanceRealWorldName(state, { globalUniqueIdentifier, realWorldName }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'realWorldName', realWorldName);
  },
  setInstanceImageUrl(state, { globalUniqueIdentifier, url }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'imageUrl', url);
  },

  updateOnlineStatus(state, {
    globalUniqueIdentifier, value, active, fullCheck = false,
  }) {
    const timestamp = Date.now();
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'online', value);
    this._vm.$set(state.lastConnectionCheckTimestamps, globalUniqueIdentifier, timestamp);
    if (fullCheck) this._vm.$set(state.lastCompleteConnectionCheckTimestamps, globalUniqueIdentifier, timestamp);

    const activeConnectionStrategy = value ? active : null;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'activeConnectionStrategy', activeConnectionStrategy);
  },

  resetConnectionCheckTimestamps(state, globalUniqueIdentifier) {
    this._vm.$delete(state.lastConnectionCheckTimestamps, globalUniqueIdentifier);
    this._vm.$delete(state.lastCompleteConnectionCheckTimestamps, globalUniqueIdentifier);
  },

  setInstanceVersion(state, { globalUniqueIdentifier, version }) {
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'version', version);
  },

  selectBarn(state, globalUniqueIdentifier) {
    state.selectedBarnId = globalUniqueIdentifier;
  },

  deselectBarn(state) {
    state.selectedBarnId = null;
  },

  setLoggedIn(state, { globalUniqueIdentifier, value, accountId = undefined }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'loggedIn', value);
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'accountId', accountId);
  },

  setRefreshToken(state, { globalUniqueIdentifier, refreshToken }) {
    if (!state.backendInstances[globalUniqueIdentifier]) return;
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'refreshToken', refreshToken);
  },
  deleteInstance(state, globalUniqueIdentifier) {
    this._vm.$delete(state.backendInstances, globalUniqueIdentifier);
  },

  logout(state, globalUniqueIdentifier) {
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'refreshToken', null);
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'accessToken', null);
    this._vm.$set(state.backendInstances[globalUniqueIdentifier], 'loggedIn', false);
  },

};

const actions = {

  async checkIfInstanceSupportsRemoteAuthentication(_, globalUniqueIdentifier) {
    const result = await Api.get({ globalUniqueIdentifier, skipAuthentication: true, path: 'api/auth/remote' });
    return result.successful === true;
  },

  async checkSupportedAuthenticationModes(_, globalUniqueIdentifier) {
    const usernamePasswordAuthPromise = Api.get({ globalUniqueIdentifier, skipAuthentication: true, path: 'api/auth/usernamePassword' });
    const emailPasswordAuthPromise = Api.get({ globalUniqueIdentifier, skipAuthentication: true, path: 'api/auth/emailPassword' });
    const remoteAuthPromise = Api.get({ globalUniqueIdentifier, skipAuthentication: true, path: 'api/auth/remote' });
    await Promise.all([usernamePasswordAuthPromise, emailPasswordAuthPromise, remoteAuthPromise]);
    const result = {
      usernamePassword: (await usernamePasswordAuthPromise).successful === true,
      emailPassword: (await emailPasswordAuthPromise).successful === true,
      remote: (await remoteAuthPromise).successful === true,
    };
    if (!result.usernamePassword && !result.emailPassword && !result.remote) result.usernamePassword = true;
    return result;
  },

  async ensureSenatorInstanceExists({ getters, commit, state }) {
    if (getters.getSenator) return;

    const senatorInstance = {
      globalUniqueIdentifier: '66',
      name: 'Senator',
      online: false,
      discoveryObject: { remote: { hostname: 'senator.vms.dev.vetvise.com', port: 443, protocol: 'https' } },
      version: {},
      loggedIn: false,
      refreshToken: null,
      accessToken: null,
      type: 'senator',
      availablePermissions: [],
      activeConnectionStrategy: null,
    };
    commit('addNewBackendInstance', senatorInstance);
  },

  async migrateBarnsToBackendInstances({ rootGetters, getters, commit }) {
    const barns = rootGetters.getAllBarnsForMigration;
    const authRecords = rootGetters.getAllAuthRecordsForMigration;
    const barnIds = Object.keys(barns);
    for (const barnId of barnIds) {
      try {
        if (barns[barnId].migrated === true) {
          logger.log('INFO', `Barn with id ${barnId} was already migrated!`);
          continue;
        }
        const guid = barns[barnId]?.discoveryObject?.gateway?.id;
        if (getters.getBackendInstance(guid)) {
          logger.log('INFO', `Barn with ID ${barnId} could not be migrated because a barn with the same guid (${guid}) already exists!`);
          continue;
        }
        if (!guid) {
          logger.log('INFO', `Barn with ID ${barnId} (${JSON.stringify(barns[barnId], null, 2)}) could not be migrated because it doesn't have a gateway record!`);
          continue;
        }
        const newInstance = {
          globalUniqueIdentifier: guid,
          name: barns[barnId].name,
          online: false,
          discoveryObject: barns[barnId].discoveryObject,
          version: {},
          loggedIn: true,
          refreshToken: authRecords[barnId].refreshToken,
          accessToken: null,
          type: 'barn',
          availablePermissions: [],
          activeConnectionStrategy: null,
        };
        commit('addNewBackendInstance', newInstance);
        commit('markBarnAsMigrated', barnId);
        logger.log('INFO', `Barn with ID ${barnId} was successfully created to guid ${guid}`);
      } catch (e) {
        logger.log('ERROR', `Barn with ID ${barnId} (${JSON.stringify(barns[barnId], null, 2)}) could not be migrated because of error ${e.stack}`);
      }
    }
  },

  async loadImageForInstance({ commit }, globalUniqueIdentifier) {
    const response = await Api.get({ globalUniqueIdentifier, path: 'api/identity/imageUrl', skipAuthentication: true });
    if (!response.successful) return;
    commit('setInstanceImageUrl', { globalUniqueIdentifier, url: response.response.data });
  },

  async checkConnectionStrategy({ commit, getters }, globalUniqueIdentifier) {
    const discoveryObject = getters.getDiscoveryObject(globalUniqueIdentifier);

    const lastConnectionCheck = getters.getLastConnectionCheck(globalUniqueIdentifier);
    const currentConnectionStrategy = getters.getBackendInstanceActiveConnectionStrategy(globalUniqueIdentifier);
    if (lastConnectionCheck && Date.now() - lastConnectionCheck < 1000 * 60 * 5 && currentConnectionStrategy === null) return undefined;
    const lastCompleteConnectionCheck = getters.getLastCompleteConnectionCheck(globalUniqueIdentifier);
    if (lastCompleteConnectionCheck && Date.now() - lastCompleteConnectionCheck < 1000 * 60 * 2) {
      let connectionPromise = null;
      switch (currentConnectionStrategy) {
        case 'local': { connectionPromise = testConnectivity(buildLocalUrl(discoveryObject)); break; }
        case 'remote': { connectionPromise = testConnectivity(buildRemoteUrl(discoveryObject)); break; }
        case 'gateway': { connectionPromise = testConnectivity(buildGatewayUrl(discoveryObject)); break; }
        default:
          break;
      }
      if (connectionPromise) {
        const connectionResponse = await connectionPromise;
        if (connectionResponse[0] === true) {
          return commit('updateOnlineStatus', {
            globalUniqueIdentifier, value: true, active: currentConnectionStrategy, fullCheck: false,
          });
        }
      }
    }

    const localPromise = testConnectivity(buildLocalUrl(discoveryObject));
    const gatewayPromise = testConnectivity(buildGatewayUrl(discoveryObject));
    const remotePromise = testConnectivity(buildRemoteUrl(discoveryObject));
    const localResponse = await localPromise;
    if (localResponse[0] === true) {
      return commit('updateOnlineStatus', {
        globalUniqueIdentifier, value: true, active: 'local', fullCheck: true,
      });
    }

    const gatewayResponse = await gatewayPromise;
    if (gatewayResponse[0] === true) {
      return commit('updateOnlineStatus', {
        globalUniqueIdentifier, value: true, active: 'gateway', fullCheck: true,
      });
    }

    const remoteResponse = await remotePromise;
    if (remoteResponse[0] === true) {
      return commit('updateOnlineStatus', {
        globalUniqueIdentifier, value: true, active: 'remote', fullCheck: true,
      });
    }

    return commit('updateOnlineStatus', { globalUniqueIdentifier, value: false, fullCheck: true });
  },

  async resetConnectionCheckTimestamps({ commit, getters }) {
    const instances = Object.keys(getters.getBackendInstances);
    instances.forEach((instance) => commit('resetConnectionCheckTimestamps', instance));
  },

  async resetConnectionCheckTimestamp({ commit }, globalUniqueIdentifier) {
    commit('resetConnectionCheckTimestamps', globalUniqueIdentifier);
  },

  async validateLogin({ commit, getters }, globalUniqueIdentifier) {
    const instance = getters.getBackendInstance(globalUniqueIdentifier);
    if (!instance) return commit('setLoggedIn', { globalUniqueIdentifier, value: false });
    if (!instance.refreshToken) return commit('setLoggedIn', { globalUniqueIdentifier, value: false });
    let decodedToken;
    try {
      decodedToken = JSON.parse(Buffer.from(instance.refreshToken.split('.')[1], 'base64').toString());
    } catch {
      return commit('setLoggedIn', { globalUniqueIdentifier, value: false });
    }
    if (!decodedToken.refresh) return commit('setLoggedIn', { globalUniqueIdentifier, value: false });
    if (decodedToken.exp * 1000 <= Date.now()) return commit('setLoggedIn', { globalUniqueIdentifier, value: false });
    return commit('setLoggedIn', { globalUniqueIdentifier, value: true, accountId: decodedToken.uid });
  },

  async loginWithUsernameAndPassword({ commit, dispatch }, { globalUniqueIdentifier, username, password }) {
    try {
      const path = 'api/auth/usernamePassword/login';
      const response = await Api.post({
        path,
        globalUniqueIdentifier,
        skipAuthentication: true,
        payload: { username, password },
      });
      if (response.successful) {
        const { refreshToken } = response.response.data;
        commit('setRefreshToken', { globalUniqueIdentifier, refreshToken });
        await dispatch('validateLogin', globalUniqueIdentifier);
        await dispatch('fetchAccessToken', globalUniqueIdentifier);
        await dispatch('fetchOwnPermissions', globalUniqueIdentifier);
        return true;
      }
    } catch {
      return false;
    }
    return false;
  },

  async loginWithEmailAndPassword({ commit, dispatch }, { globalUniqueIdentifier, email, password }) {
    try {
      const path = 'api/auth/emailPassword/login';
      const response = await Api.post({
        path,
        globalUniqueIdentifier,
        skipAuthentication: true,
        payload: { email, password },
      });
      if (response.successful) {
        const { refreshToken } = response.response.data;
        commit('setRefreshToken', { globalUniqueIdentifier, refreshToken });
        await dispatch('validateLogin', globalUniqueIdentifier);
        await dispatch('fetchAccessToken', globalUniqueIdentifier);
        await dispatch('fetchOwnPermissions', globalUniqueIdentifier);
        return true;
      }
      if (response.error.response.status === 412) {
        return 'email-not-verified';
      }
    } catch {
      return false;
    }
    return false;
  },

  async loginWithIdentityProvider({ commit, dispatch, getters }, globalUniqueIdentifier) {
    try {
      const senator = await getters.getSenator;
      if (!senator) return false;
      const serviceProviders = await dispatch('fetchServiceProviders');
      if (!serviceProviders) return false;
      const serviceProvider = serviceProviders.find((serviceProviderEntry) => serviceProviderEntry.globalUniqueIdentifier === globalUniqueIdentifier);
      if (!serviceProvider) return false;
      if (!serviceProvider.remoteAccountId) return false;
      if (!serviceProvider.remoteAuthenticationId) return false;
      const response = await Api.get({
        globalUniqueIdentifier: senator.globalUniqueIdentifier,
        path: `api/idp/${serviceProvider.serviceProviderId}/login/`,
      });
      if (response.successful) {
        const { refreshToken } = response.response.data;
        commit('setRefreshToken', { globalUniqueIdentifier, refreshToken });
        await dispatch('validateLogin', globalUniqueIdentifier);
        await dispatch('fetchAccessToken', globalUniqueIdentifier);
        await dispatch('fetchOwnPermissions', globalUniqueIdentifier);
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  },

  async registerWithEmailAndPassword({ commit, dispatch }, {
    globalUniqueIdentifier, email, password, passwordRepetition, acceptEula,
  }) {
    try {
      const response = await Api.post({
        globalUniqueIdentifier,
        path: 'api/auth/emailPassword',
        skipAuthentication: true,
        payload: {
          email,
          password,
          passwordRepetition,
          acceptEula,
        },
      });
      return response.successful;
    } catch {
      return false;
    }
  },

  async registerWithUsernameAndPassword({ commit, dispatch }, {
    globalUniqueIdentifier, username, password, passwordRepetition, acceptEula, displayName = undefined,
  }) {
    try {
      const response = await Api.post({
        globalUniqueIdentifier,
        path: 'api/auth/usernamePassword',
        skipAuthentication: true,
        payload: {
          username,
          password,
          displayName,
          passwordRepetition,
          acceptEula,
        },
      });
      return response.successful;
    } catch {
      return false;
    }
  },
  async getCurrentEULA(_, globalUniqueIdentifier) {
    const response = await Api.get({
      globalUniqueIdentifier,
      path: 'api/eulas/current',
      responseType: 'blob',
      skipAuthentication: true,
    });
    return response.response.data;
  },

  // async createAccountWithUsernameAndPassword(
  //   _,
  //   {
  //     barnId, username, password, passwordRepetition, eula, displayName,
  //   },
  // ) {
  //   const response = await Api.post({
  //     barnId,
  //     path: 'api/auth/usernamePassword',
  //     skipAuthentication: true,
  //     payload: {
  //       username,
  //       password,
  //       passwordRepetition,
  //       displayName,
  //       acceptEula: eula,
  //     },
  //   });
  //   return response;
  // },
  async getEulaVersions(_, globalUniqueIdentifier) {
    const response = await Api.get({
      globalUniqueIdentifier,
      path: 'api/eulas',
      skipAuthentication: true,
    });
    if (response.successful) return response.response.data;
    return [];
  },
  // async getCurrentEULA(_, barnId) {
  //   const response = await Api.get({
  //     barnId,
  //     path: 'api/eulas/current',
  //     responseType: 'blob',
  //     skipAuthentication: true,
  //   });
  //   return response.response;
  // },
  // async makeEulaRequest({ commit, getters }) {
  //   try {
  //     const url = 'api/eulas/current';
  //     const { response } = await Api.get({ path: url, responseType: 'blob' });
  //     const string = response.headers['content-disposition'];
  //     const getExtension = string.substr(
  //       string.indexOf('"') + 1,
  //       string.lastIndexOf('"') - (string.indexOf('"') + 1),
  //     );
  //     const fileURL = URL.createObjectURL(response.data);
  //     const data = {
  //       data: fileURL,
  //       response: getExtension,
  //       id: getters.selectedBarnId,
  //     };
  //     commit('updateEula', data);
  //     return response;
  //   } catch (error) {
  //     const data = {
  //       data: null,
  //       response: null,
  //       id: getters.selectedBarnId,
  //     };
  //     commit('updateEula', data);
  //   }
  //   return undefined;
  // },
  //
  // async registerUser(_, payload) {
  //   try {
  //     const endpoint = 'api/accounts';
  //     const responsePayload = { ...payload };
  //     delete responsePayload.id;
  //     const { response } = await Api.post({ path: endpoint, payload: responsePayload });
  //     return response;
  //   } catch (error) {
  //     console.log(error);
  //     return error;
  //   }
  // },
  //
  async fetchAccessToken({ commit, getters, dispatch }, { globalUniqueIdentifier, acceptedEula }) {
    try {
      await dispatch('validateLogin', globalUniqueIdentifier);
      if (!getters.isLoggedInIntoInstance(globalUniqueIdentifier)) return false;
      const endpoint = 'api/login/refresh';
      const { refreshToken } = getters.getBackendInstance(globalUniqueIdentifier);
      const response = await Api.post({
        skipAuthentication: true,
        globalUniqueIdentifier,
        path: endpoint,
        payload: {
          refreshToken, acceptEula: acceptedEula || undefined,
        },
      });
      if (!response.successful) {
        if (response.error.response.status === 403) {
          await dispatch('validateLogin', globalUniqueIdentifier);
          return false;
        }
        if (response.error.response.status === 451) {
          commit('markEulaAsNeeded', globalUniqueIdentifier);
          return false;
        }
        return false;
      }
      commit('markEulaAsResolved', globalUniqueIdentifier);
      commit('setAccessToken', { globalUniqueIdentifier, token: response.response.data.accessToken });
      return true;
    } catch { return false; }
  },
  // async getAccessToken({ commit, getters }, { id, acceptedEula }) {
  //   try {
  //     const endpoint = 'api/login/refresh';
  //     const refreshToken = getters.getRefreshToken(id);
  //     if (!refreshToken) {
  //       if (getters.isLoggedInIntoBarn(id)) commit('logout', { id });
  //       return false;
  //     }
  //     const response = await Api.post({
  //       barnId: id,
  //       path: endpoint,
  //       payload: {
  //         refreshToken,
  //         acceptEula: acceptedEula || undefined,
  //       },
  //     });
  //     if (response.successful) {
  //       commit('eulaResolved', id);
  //       commit('updateAccessToken', {
  //         id,
  //         token: response.response.data.accessToken,
  //       });
  //     } else if (response.error.response.status === 403) {
  //       commit('logout', { id });
  //     } else if (response.error.response.status === 451) {
  //       commit('eulaNeeded', id);
  //     }
  //     return true;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return false;
  // },
  //
  // async getAllPermissions({ commit }, { id }) {
  //   try {
  //     const endpoint = 'api/authorization/permissions/all';
  //     const { response } = await Api.get({ path: endpoint });
  //     commit('setAllPermissions', {
  //       id,
  //       permissions: response.data,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return undefined;
  // },
  //
  async fetchOwnPermissions({ commit }, globalUniqueIdentifier) {
    try {
      const endpoint = 'api/authorization/permissions/?recursive=true';
      const response = await Api.get({ globalUniqueIdentifier, path: endpoint });
      if (response.successful) {
        commit('setOwnPermissions', {
          globalUniqueIdentifier,
          permissions: response.response.data.map((permission) => permission.id),
        });
      }
    } catch (error) {
      logger.log('ERROR', error.stack);
    }
    return undefined;
  },

  async loadVersion({ commit }, globalUniqueIdentifier) {
    const response = await Api.get({ globalUniqueIdentifier, path: 'api/v', skipAuthentication: true });
    if (!response.successful) return;
    commit('setInstanceVersion', { globalUniqueIdentifier, version: response.response.data });
  },

  async createServiceProvider({ getters }, { globalUniqueIdentifier, name }) {
    const senator = await getters.getSenator;
    if (!senator) return false;
    const response = await Api.post({
      globalUniqueIdentifier: senator.globalUniqueIdentifier,
      path: 'api/serviceProviders',
      payload: { name, globalUniqueIdentifier },
    });
    return response.successful;
  },

  async createAccountAtServiceProvider({ getters }, {
    serviceProviderId, displayName, acceptEula,
  }) {
    const senator = await getters.getSenator;
    if (!senator) return null;
    const response = await Api.post({
      globalUniqueIdentifier: senator.globalUniqueIdentifier,
      path: `api/idp/${serviceProviderId}/account`,
      payload: { displayName, acceptEula },
    });
    return response.successful;
  },
  async fetchServiceProviders({ getters }) {
    const senator = await getters.getSenator;
    if (!senator) return null;
    const response = await Api.get({ globalUniqueIdentifier: senator.globalUniqueIdentifier, path: 'api/serviceProviders/' });
    if (!response.successful) return null;
    return response.response.data;
  },

  async logOutOfInstance({ commit, getters }, { globalUniqueIdentifier, skipNavigation = false }) {
    try {
      await Api.delete({
        globalUniqueIdentifier,
        path: `api/login/${getters.getBackendInstance(globalUniqueIdentifier).refreshToken}`,
      });
    } catch (e) {
      logger.log('ERROR', `Could not log out of barn ${globalUniqueIdentifier} because of ${e.stack}`);
    }
    commit('logout', globalUniqueIdentifier);
    if (!skipNavigation && router.currentRoute.name !== 'SelectBarn') {
      logger.log('DEBUG', `Navigating to Select Barn because the User logged out of the barn! ${globalUniqueIdentifier}`);
      await router.replace({ name: 'SelectBarn' });
    }
  },

  async verifyEmail(_, { discoveryObject, authenticationId, verificationToken }) {
    return Api.post({
      skipAuthentication: true,
      skipGlobalUniqueIdentifier: true,
      url: `${discoveryObject.remote.protocol}://${discoveryObject.remote.hostname}:${discoveryObject.remote.port}/api/auth/emailPassword/verify`,
      payload: { authenticationId, verificationToken },
    });
  },

  async resendEmailVerification(_, { discoveryObject = undefined, email, globalUniqueIdentifier = undefined }) {
    if (discoveryObject && !globalUniqueIdentifier) {
      return (await Api.post({
        skipAuthentication: true,
        skipGlobalUniqueIdentifier: true,
        url: `${discoveryObject.remote.protocol}://${discoveryObject.remote.hostname}:${discoveryObject.remote.port}/api/auth/emailPassword/verification/new`,
        payload: { email },
      })).successful;
    }
    return (await Api.post({
      skipAuthentication: true,
      globalUniqueIdentifier,
      path: 'api/auth/emailPassword/verification/new',
      payload: { email },
    })).successful;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
