import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Modal from '../components/Modal.vue';
import Confirmation from '../components/Confirmation.vue';
import BackButton from '../components/BackButton.vue';
import IconLoader from '../components/IconLoader.vue';

Vue.filter('formatDate', (value) => {
  if (!value) return '';
  const date = new Date(value);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return `${day}.${month}.${year}`;
});

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 3000,
});

Vue.component('vet-modal', Modal);
Vue.component('vet-confirmation', Confirmation);
Vue.component('vet-back-button', BackButton);
Vue.component('IconLoader', IconLoader);
