<template>
<v-container  class="ma-0 pa-0"  fluid>
  <v-layout justify-end>
    <v-btn-toggle class="mt-4" v-model="layoutIndex" mandatory rounded>
      <v-btn><v-icon>view_comfy</v-icon></v-btn>
      <v-btn><v-icon>view_list</v-icon></v-btn>
    </v-btn-toggle>
  </v-layout>

  <v-alert class="ma-4" v-if="incidents !== null && incidents.length === 0" type="info" prominent>
    There is nothing here!
  </v-alert>

  <v-dialog v-model="exportDialog" width="auto" overlay-opacity=".95" persistent v-if="incidents !== null && incidents.length !== 0">
    <template v-slot:activator="{on}">
      <v-btn v-on="on" rounded class="small" >Export List</v-btn>
    </template>
    <v-card dark rounded :loading="eventsLoading" :disabled="eventsLoading">
      <v-card-title>Export List</v-card-title>
      <v-card-text>
        <v-switch label="Export Everything" v-model="exportEverything" class="ma-4"></v-switch>
        <NewDTPicker label="Start of Export" v-model="exportStart" :disabled="exportEverything"></NewDTPicker>
        <NewDTPicker label="End of Export" v-model="exportEnd" :disabled="exportEverything"></NewDTPicker>
        <v-text-field label="Filename" v-model="fileName" rounded outlined suffix=".xlsx"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <v-btn rounded class="error--text mx-2" v-on:click="exportDialog = false">Cancel</v-btn>
          <vue-excel-xlsx
            :data="xlsxData"
            :columns="headersForXLSX"
            :file-name="fileName"
            :file-type="'xlsx'"
            :sheet-name="'Interventions'">
            <v-btn rounded class="primary mx-2"  :disabled="xlsxData.length === 0" v-on:click="exportDialog = false">Download List</v-btn>
          </vue-excel-xlsx>
        </v-layout>

      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container class="ma-0 pa-0" fluid v-if="layoutIndex === 0">
    <AbstractIncident
      v-for="intervention in incidents"
      v-bind:key="intervention.incidentId"
      :incident="intervention"
      :enable-more-button="true"
      :enable-close-incident-button="true"
      :enable-delete-incident-button="true"
      v-on:reload="reload"
    >
    </AbstractIncident>
  </v-container>

<v-data-table v-if="layoutIndex === 1"
              :headers="headers.filter(header => header.table)"
              :items="incidents"
              :options="{
                itemsPerPage: 50,
                multiSort: true
              }"
>
  <template v-slot:[`item.openedAt`]="{ item }">
    <span>{{ humanReadableTimestamp(item.openedAt) }}</span>
  </template>

</v-data-table>
</v-container>
</template>

<script>
import AbstractIncident from '@/components/Incidents/AbstractIncident.vue';
import NewDTPicker from '@/components/NewDTPicker.vue';

export default {
  name: 'InterventionList',
  components: { NewDTPicker, AbstractIncident },
  props: { incidents: Array, typeOptions: Array, globalInterventions: Boolean },

  data() {
    return {
      fileName: '',
      exportEverything: true,
      exportStart: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7 * 52),
      exportEnd: null,
      exportDialog: false,
      layoutIndex: 0,
      headers: [
        {
          text: this.$t('interventions.realWorldName'), value: 'realWorldName', table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.opened_at'), value: 'openedAt', dataFormat: (value) => (!value ? '' : this.humanReadableTimestamp(value)), table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.severity'), value: 'messagePayload.severity', dataFormat: (value) => (value ? this.$t(`interventions.severities.${value}`) : ''), table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.timestamp'), value: 'eventTimestamp', dataFormat: (value) => (!value ? '' : this.humanReadableTimestamp(value)), table: false, xlsx: true,
        },
        {
          text: this.$t('interventions.title'), value: 'messagePayload.title', table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.description'), value: 'messagePayload.description', table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.state'), value: 'state', dataFormat: (value) => (value ? this.$t(`interventions.states.${value}`) : ''), table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.type'), value: 'messagePayload.type', dataFormat: (value) => (value ? this.$t(`interventions.types.${value}`) : ''), table: true, xlsx: true,
        },
        {
          text: this.$t('interventions.subtype'), value: 'messagePayload.issue', dataFormat: (value) => (value ? (this.$t(`interventions.issues.${value}`)) : ''), table: true, xlsx: true,
        },
      ],
      events: null,
      eventsLoading: true,
    };
  },
  watch: {
    async exportDialog(value) {
      if (!value) return;
      if (!this.globalInterventions) this.fileName = `${this.$t('interventions.filename')}_${this.$store.getters.getSelectedBarn.name}`;
      else {
        this.fileName = `${this.$t('interventions.filename')}`;
      }
      if (this.events) return;
      this.eventsLoading = true;
      this.events = [];
      if (!this.globalInterventions) {
        this.events = await this.$store.dispatch('loadAllEventsForIncidentList', { incidentIds: this.incidents.map((incident) => incident.incidentId) });
      } else {
        this.events = await this.$store.dispatch('loadAllEventsForMultiInstanceIncidentList', { incidents: this.incidents });
      }
      this.eventsLoading = false;
    },
  },
  computed: {
    headersForXLSX() {
      return this.headers.filter((header) => header.xlsx).map((header) => ({ label: header.text, field: header.value, dataFormat: header.dataFormat }));
    },
    xlsxData() {
      if (!this.incidents || !this.events) { return []; }
      const items = [];
      let { incidents } = this;
      if (!this.exportEverything) {
        incidents = incidents.filter((incident) => incident.openedAt >= this.exportStart.getTime() && incident.openedAt <= this.exportEnd.getTime());
      }
      incidents = incidents.sort((a, b) => a.openedAt - b.openedAt);
      incidents.forEach((incident) => {
        items.push(incident);
        const events = this.events.filter((incidentEvent) => incidentEvent.incidentId === incident.incidentId && (incidentEvent.globalUniqueIdentifier === incident.globalUniqueIdentifier || !this.globalInterventions)).sort((a, b) => a.eventTimestamp - b.eventTimestamp);
        events.forEach((incidentEvent) => {
          items.push({
            globalUniqueIdentifier: this.globalInterventions ? incidentEvent.globalUniqueIdentifier : undefined,
            realWorldName: this.globalInterventions ? incidentEvent.realWorldName : undefined,
            messagePayload: incidentEvent.payload ? incidentEvent.payload : {},
            eventTimestamp: incidentEvent.eventTimestamp,
          });
        });
      });
      return items;
    },
  },
  methods: {
    async reload() {
      return this.$emit('reload');
    },
  },
};
</script>

<style scoped>

</style>
