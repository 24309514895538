<template>
  <v-card dark class="my-4">
    <v-card-title v-if="!this.new">Trigger New Event</v-card-title>
    <v-card-title v-else>New Barn Intervention</v-card-title>
    <v-card-text>
     <v-form ref="form">
      <v-text-field :rules="titleRules" label="Title" outlined v-model="interventionTitle"></v-text-field>
      <v-select
        :rules="typeRules"
        :readonly="this.new !== true"
        :disabled="this.new !== true"
        label="Type of Intervention"
        :items="types"
        item-text="text"
        item-value="value"
        v-model="interventionType">
      </v-select>
      <v-textarea :rules="descriptionRules" v-model="interventionDescription" label="Description" outlined></v-textarea>
      <v-container class="ma-0 pa-0" v-if="typeSetup && typeSetup[interventionType] && typeSetup[interventionType].length > 0">
        <v-radio-group :label="$t('interventions.subtype')" v-model="issue" :rules="issueRules">
          <v-radio v-bind:key="issue" v-for="issue in typeSetup[interventionType]" :value="issue" :label="$t('interventions.issues.'+issue)"></v-radio>
        </v-radio-group>
      </v-container>
      <v-slider
        class="ma-2"
        label="Severity"
        :color="severityColor"
        v-model="severityIndex"
        :tick-labels="severityLabels"
        ticks="always"
        step="1"
        :max="3">
      </v-slider>
      <v-checkbox label="Does the issue still persist?" v-model="issuePersists"></v-checkbox>
     </v-form>
      </v-card-text>
    <v-card-actions>
      <v-layout justify-end><v-btn primary rounded v-on:click="triggerEvent">Trigger Event</v-btn></v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'BarnInterventionIncidentEventTriggerDialog',
  props: { incident: Object, new: Boolean },
  data() {
    return {
      issue: null,
      titleRules: [
        (value) => !!value || i18n.t('validation_rules.required'),
      ],
      typeRules: [
        (value) => !!value || i18n.t('validation_rules.required'),
      ],
      issueRules: [
        (value) => (!!value && this.typeSetup[this.interventionType].includes(value)) || i18n.t('validation_rules.required'),
      ],
      descriptionRules: [
        (value) => !!value || i18n.t('validation_rules.required'),
      ],
      severityIndex: 2,
      severities: ['none', 'low', 'middle', 'high'],
      severityLabels: ['None', 'Low', 'Middle', 'High'],
      temperatureRating: null,
      interventionDescription: '',
      interventionTitle: '',
      interventionType: null,
      issuePersists: false,
      typeSetup: {},
      newTypeSetup: false,
    };
  },
  async mounted() {
    this.newTypeSetup = true;
    const typeSetupHolder = (await this.$store.dispatch('getIncidentSchema', { incidentType: 'barnIntervention' }));
    if (typeSetupHolder.barnIntervention) this.typeSetup = typeSetupHolder.barnIntervention.typeSetup;
    else {
      this.newTypeSetup = false;
      this.typeSetup = {
        climate: ['all good', 'way too cold', 'too cold', 'too warm', 'way too warm', 'draft'],
        moisture: [],
        technical: [],
        stress: [],
        other: [],
      };
    }
    if (this.new !== true) {
      this.interventionType = this.incident.messagePayload.type;
      this.interventionTitle = this.incident.messagePayload.title;
      this.interventionDescription = this.incident.messagePayload.description;
      this.severityIndex = this.severities.indexOf(this.incident.messagePayload.severity);
      if (this.incident.messagePayload.type === 'climate') {
        this.temperatureRating = this.incident.messagePayload.typeSpecificPayload.temperatureRating;
      }
      this.issuePersists = this.incident.state === 'actionRequired';
    } else {
      this.issuePersists = true;
    }
  },
  computed: {
    types() {
      return Object.keys(this.typeSetup).map((type) => ({ value: type, text: this.$t(`interventions.types.${type}`) }));
    },
    severity() {
      return this.severities[this.severityIndex];
    },
    severityColor() {
      if (this.severityIndex === 3) return 'red';
      if (this.severityIndex === 2) return 'orange';
      if (this.severityIndex === 1) return 'green';
      if (this.severityIndex === 0) return 'blue';
      return 'white';
    },
  },
  methods: {
    async triggerEvent() {
      if (!this.$refs.form.validate()) {
        this.$toast.error(i18n.t('general.fix_errors'));
        return;
      }
      const payload = {
        type: this.interventionType,
        title: this.interventionTitle,
        description: this.interventionDescription,
        severity: this.severity,
      };
      if (this.newTypeSetup && this.typeSetup[this.interventionType].length > 0) {
        payload.issue = this.issue;
      }
      if (!this.newTypeSetup && this.interventionType === 'climate') {
        payload.typeSpecificPayload = {
          temperatureRating: this.issue,
        };
      }
      const trigger = this.issuePersists ? 'issueDetected' : 'noIssueDetected';
      const record = {
        payload,
        type: 'barnIntervention',
        trigger,
        identifier: {},
      };
      if (this.new !== true) record.identifier = this.incident.identifier;
      this.$emit('triggerevent', record);
    },
  },
};
</script>

<style scoped>

</style>
