/* eslint-disable no-use-before-define */
import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import QuickLinks from '@/views/Main/QuickLinks.vue';
import SSO from '@/views/SSO.vue';
import GlobalInterventions from '@/views/Dashboard/GlobalInterventions.vue';
import store from '../store/index';

const logger = require('../Logger');

const Barn = () => import('../views/Main/Barn.vue');
const SelectBarn = () => import('../views/Main/SelectBarn.vue');
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Barn',
    component: Barn,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.getSelectedBarnId && store.getters.getSelectedBarn && store.getters.getSelectedBarn.loggedIn
      ) {
        logger.log('DEBUG', `Navigating to Dashboard for barn ${to.params.barnId} because there's a barn selected!`);
        return router.push({ name: 'Dashboard', params: { barnId: to.params.barnId } });
      }
      if (
        store.getters.getSelectedBarnId && (!store.getters.getSelectedBarn || !store.getters.getSelectedBarn.loggedIn)
      ) {
        logger.log('DEBUG', `Not Navigating to Dashboard for barn ${to.params.barnId} because Auth is invalid! (Tried to navigate to /)`);
        if (router.currentRoute.name !== 'SelectBarn') {
          logger.log('Navigating back to Select Barn! because Auth is invalid!');
          return router.push({ name: 'SelectBarn' });
        }
        return undefined;
      }
      return next();
    },
  },
  {
    path: '/select-barn',
    name: 'SelectBarn',
    component: SelectBarn,
    beforeEnter: (to, from, next) => {
      logger.log('DEBUG', `Entering Barn Selection, Came from ${from.path}`);
      logger.log('DEBUG', 'Deselecting Barn!');
      store.commit('deselectBarn');
      return next();
    },
  },
  {
    path: '/single-sign-on/:targetInstance',
    name: 'SSO',
    component: SSO,
  },
  {
    path: '/ql/:action',
    name: 'QuickLinks',
    component: QuickLinks,
  },
  {
    path: '/interventions',
    name: 'Global Interventions',
    component: GlobalInterventions,
    meta: {
      icon: 'receipt_long',
      titleId: 'routes.interventions',
    },
  },
  {
    name: 'Dashboard',
    path: '/dashboard/:barnId/',
    component: () => import('../Layout/Dashboard.vue'),
    redirect: { name: 'Camera' },
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedInIntoInstance(to.params.barnId)) {
        logger.log('DEBUG', `Not Navigating to Dashboard for barn ${to.params.barnId} because Auth is invalid! (Tried to navigate directly to Dashboard)`);
        if (router.currentRoute.name !== 'SelectBarn') return router.push({ name: 'SelectBarn' });
        return undefined;
      }
      if (store.getters.getSelectedBarnId !== to.params.barnId) { store.commit('clearCameras'); }
      store.commit('selectBarn', to.params.barnId);
      return next();
    },
    children: [
      {
        path: 'documentation',
        name: 'BarnDocumentation',
        meta: {
          icon: 'receipt_long',
          titleId: 'routes.barnDocumentation',
        },
        component: () => import('../views/Dashboard/Summary/BarnDocumentation.vue'),
      },
      {
        path: 'interventions',
        name: 'Interventions',
        meta: {
          titleId: 'routes.interventions',
        },
        component: () => import('../views/Dashboard/Summary/Interventions.vue'),
      },
      {
        path: 'speedWatchdog',
        name: 'SpeedWatchdog',
        component: () => import('../views/SpeedWatchdog.vue'),
        meta: {
          titleId: 'routes.speedWatchdog',
        },
      },
      {
        path: 'incident/:incidentId',
        name: 'Incident',
        component: () => import('../views/Dashboard/Summary/Incident.vue'),
        meta: {
          titleId: 'routes.incident',
        },
      },
      {
        path: 'dead-animal-map',
        name: 'DeadAnimalMap',
        component: () => import('../views/Dashboard/Summary/DeadAnimalMap.vue'),
        meta: {
          titleId: 'routes.deadAnimalMap',
        },
      },
      {
        path: 'dead-animal-statistics',
        name: 'DeadAnimalStatistics',
        component: () => import('../views/Dashboard/DeadAnimalStatistics.vue'),
        meta: {
          titleId: 'routes.deadAnimalStatistics',
        },
      },
      {
        path: 'overview',
        name: 'AtAGlance',
        component: () => import('../views/Dashboard/Summary/AtAGlance.vue'),
        meta: {
          titleId: 'routes.atAGlance',
        },
      },
      {
        path: 'canvas-creator',
        name: 'CanvasCreator',
        component: () => import('../views/Dashboard/Camera/CanvasCreator.vue'),
        meta: {
          titleId: 'routes.canvasCreator',
        },
      },
      {
        path: 'canvas-creator/:canvasId',
        name: 'CanvasEditor',
        component: () => import('../views/Dashboard/Camera/CanvasCreator.vue'),
        meta: {
          titleId: 'routes.canvasCreator',
        },
      },
      {
        path: 'camera-canvas/:canvasId',
        name: 'CameraCanvas',
        component: () => import('../views/Dashboard/Camera/CameraCanvas.vue'),
        meta: {
          titleId: 'routes.canvas',
        },
      },
      {
        path: 'canvas-list',
        name: 'CanvasList',
        component: () => import('../views/Dashboard/Camera/CanvasList.vue'),
        meta: {
          titleId: 'routes.canvasList',
        },
      },
      {
        path: 'camera',
        name: 'Camera',
        component: () => import('../views/Dashboard/Camera/Camera.vue'),
        meta: {
          withoutAppBar: true,
        },
        children: [
          {
            path: ':cameraId',
            name: 'Big Camera',
            meta: {
              withoutAppBar: true,
              titleId: 'routes.cameraView',
            },
            components: {
              cameraview: () => import('../components/Dashboard/BigCamera.vue'),
            },
          },
        ],
      },
      {
        path: 'camera/edit/:id/:name',
        name: 'Edit Camera',
        component: () => import('../views/Dashboard/Camera/EditCamera.vue'),
        meta: {
          titleId: 'routes.editCamera',
        },
      },
      {
        path: 'camera/graph/:cameraId/:name',
        name: 'Camera Graph',
        component: () => import('../views/Dashboard/Camera/Graphs.vue'),
        meta: {
          titleId: 'routes.cameraGraph',
        },
      },
      {
        path: 'grid',
        name: 'Grid',
        component: () => import('../views/Dashboard/Camera/CameraGrid.vue'),
        meta: {
          titleId: 'routes.cameraGrid',
        },
      },
      {
        path: 'camera/images/:id/:name',
        name: 'Camera Quick Images',
        component: () => import('../views/Dashboard/Camera/Images.vue'),
        meta: {
          titleId: 'routes.quickImages',
        },
      },
      {
        path: 'camera/streaming/:id/:name',
        name: 'Camera Streaming',
        component: () => import('../views/Dashboard/Camera/Streaming.vue'),
        meta: {
          titleId: 'routes.cameraStreaming',
        },
      },
      {
        path: 'summary',
        name: 'Summary',
        component: () => import('../views/Dashboard/Summary/Summary.vue'),
        meta: {
          titleId: 'routes.summary',
          icon: 'mdi-google-analytics',
        },
      },
      {
        path: 'frame-export',
        name: 'Frame-Export',
        component: () => import('../views/Dashboard/Recording/FrameExport.vue'),
        meta: {
          icon: 'mdi-image',
          titleId: 'routes.frameExport',
        },
      },
      {
        path: 'image-carousels',
        name: 'ImageCarousels',
        component: () => import('../views/Dashboard/Recording/ImageCarousels.vue'),
        meta: {
          titleId: 'routes.imageCarousel',
          icon: 'mdiViewCarouselOutline',
        },
      },
      {
        path: 'recordings',
        name: 'Recordings',
        component: () => import('../views/Dashboard/Recording/VideoOnDemand.vue'),
        meta: {
          titleId: 'routes.recordings',
          icon: 'mdi-video',
        },
      },
      {
        path: 'recordingExport',
        name: 'RecordingExport',
        component: () => import('../views/Dashboard/Recording/RecordingExport.vue'),
        meta: {
          titleId: 'routes.recordingExport',
        },
      },
      {
        path: 'videoplayer/:cameraId/:identifierType/:identifier',
        name: 'VideoPlayer',
        component: () => import('../views/Dashboard/Recording/VideoPlayer.vue'),
        meta: {
          titleId: 'routes.videoPlayer',
        },
      },
      {
        path: 'incidents',
        name: 'Incidents',
        component: () => import('../views/Dashboard/Summary/Incidents.vue'),
        meta: {
          titleId: 'routes.incidents',
        },
      },
      {
        path: 'artifacts',
        name: 'Artifacts',
        component: () => import('../views/Dashboard/Summary/Artifacts.vue'),
        meta: {
          titleId: 'routes.artifacts',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../views/Dashboard/Settings.vue'),
        meta: {
          titleId: 'routes.settings',
          icon: 'mdi-cog',
        },
      },
      {
        path: 'labs',
        name: 'Labs',
        component: () => import('../views/Dashboard/Labs.vue'),
        meta: {
          titleId: 'routes.labs',
          icon: 'md-science',
        },
      },
      {
        path: 'administration/createCamera',
        name: 'Create Camera',
        component: () => import('../views/Dashboard/Camera/CreateCamera.vue'),
        meta: {
          titleId: 'routes.createCamera',
          icon: 'mdi-cog',
        },
      },
      {
        path: 'administration/cameraOverview',
        name: 'Camera Overview',
        component: () => import('../views/Dashboard/Administration/CameraOverview.vue'),
        meta: {
          titleId: 'routes.cameraOverview',
        },
      },
      {
        path: 'administration/authorization',
        name: 'Administration Users',
        component: () => import('../views/Dashboard/Administration/Authorization.vue'),
        meta: {
          titleId: 'routes.authorization',
          icon: 'mdi-cog',
        },
      },
      {
        path: 'administration/service-accounts',
        name: 'Service Accounts',
        component: () => import('../views/Dashboard/Administration/ServiceAccounts.vue'),
        meta: {
          titleId: 'routes.serviceAccounts',
          icon: 'mdi-cog',
        },
      },
      {
        path: 'administration/roi-wizard',
        name: 'Regions Of Interest Setup',
        component: () => import('../views/Dashboard/Administration/ROIWizard.vue'),
        meta: {
          titleId: 'routes.roiWizard',
          icon: 'mdi-map-marker',
        },
      },
      {
        path: 'administration/roi-types',
        name: 'Region Types',
        component: () => import('../views/Dashboard/Administration/Regions/RegionTypes.vue'),
        meta: {
          titleId: 'routes.roiTypes',
        },
      },
      {
        path: 'administration/roi-specifications',
        name: 'Region Specifications',
        component: () => import(
          '../views/Dashboard/Administration/Regions/RegionSpecifications.vue'
        ),
        meta: {
          titleId: 'routes.roiSpecs',
        },
      },
      {
        path: 'administration/polygon-editor/:cameraId',
        name: 'PolygonEditor',
        component: () => import('../views/Dashboard/Administration/Regions/PolygonEditor.vue'),
        meta: {
          titleId: 'routes.polygonEditor',
        },
      },
    ],
  },
  {
    path: '/accessibility',
    name: 'Accessibility',
    component: () => import('../views/Dashboard/Accessibility/Accessibility.vue'),
    meta: {
      titleId: 'routes.accessibility',
      icon: 'mdi-human',
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  logger.log('DEBUG', `Navigating from ${from.fullPath} to ${to.fullPath}`);
  return next();
});

export default router;
