<template>
  <v-app>
    <v-main style="max-width: 100vw; background-color: #252936;">
      <router-view></router-view>
      <v-dialog persistent v-model="showLanguageDialog" width="500px" dark>
        <v-card>
          <v-card-title>{{ $t("general.choose_language") }}</v-card-title>
          <v-card-text>
            <v-list-item-group v-model="selectedLanguage">
              <v-list-item
                shaped
                rounded
                v-for="(language, langId) of languages"
                v-bind:key="langId"
              >
                <v-list-item-avatar>
                  <flag
                    :iso="language.flagId"
                    :squared="true"
                    style="font-size: 50px"
                  ></flag>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ language.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-card-actions>
            <v-btn block v-on:click="selectlanguage">{{
                $t("general.confirm")
              }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import i18n from './i18n';

const logger = require('./Logger');
const version = require('./version');

export default {
  data() {
    return {
      showLanguageDialog: false,
      showNavigationDrawer: false,
      selectedLanguage: 'en',
    };
  },
  computed: {
    ...mapGetters([
      'getLang',
      'getEulaStatus',
      'getSelectedBarn',
      'hasAllPermissions',
      'isLanguageSet',
      'languages',
      'getSelectedBarnId',
    ]),
  },
  async mounted() {
    logger.log('INFO', `Starting PigBrother from ${window.location.href} with version ${version.default}`);
    if (this.$vuetify.breakpoint.mdAndUp) this.showNavigationDrawer = true;
    logger.log('DEBUG', `Starting on a Breakpoint of
    ${this.$vuetify.breakpoint.name}
    with a width of ${this.$vuetify.breakpoint.width}
    and a height of ${this.$vuetify.breakpoint.height}`);
    this.showLanguageDialog = !this.isLanguageSet;
    this.selectedLanguage = Object.keys(this.languages).indexOf(this.getLang);
    i18n.locale = this.getLang;
  },

  watch: {
    selectedLanguage() {
      i18n.locale = Object.keys(this.languages)[this.selectedLanguage];
    },
  },
  methods: {
    updateFrontend(event) {
      logger.log('INFO', 'Found new Version. Updating...');
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Reloading!');
        window.location.reload();
      });
      if (!event.detail || !event.detail.waiting) return;
      event.detail.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    selectlanguage() {
      this.$store.commit(
        'setLang',
        Object.keys(this.languages)[this.selectedLanguage],
      );
      this.showLanguageDialog = false;
    },
    async acceptEula() {
      const res = await this.$store.dispatch('fetchAccessToken', {
        globalUniqueIdentifier: this.getSelectedBarnId,
        acceptedEula: this.getSelectedBarn.eula.response,
      });
      if (res) {
        this.$store.commit('set', { type: 'checkEula', data: false });
        await this.$store.dispatch('fetchOwnPermissions', this.getSelectedBarnId);
      }
    },
  },
  created() {
    document.addEventListener('swUpdated', this.updateFrontend, { once: true });
  },
};
</script>
