<template>
<v-container fluid class="fill-height">
  <v-layout align-center justify-center>
    <v-card dark v-if="action === 'email-verification'" style="border-radius: 20px">
      <v-card-title>Verifying Email Address</v-card-title>
      <v-card-text v-if="loading">
        Hello <strong>{{displayName}} </strong>, please wait while we verify your email address
        <v-layout justify-center class="my-4s"><v-progress-circular :size="30" :width="5" indeterminate></v-progress-circular></v-layout>
      </v-card-text>
      <v-card-text v-else-if="!this.loading && this.success">
        <v-alert type="success">Successfully verified email address! You can close this page now.</v-alert>
      </v-card-text>
      <v-card-text v-else-if="!this.loading && this.error">
        <v-alert type="error">Could not verify email address</v-alert>
      </v-card-text>
      <v-card-actions v-if="this.error">
        <v-dialog v-model="showResendVerificationEmailDialog" width="auto" overlay-opacity="1">
          <template v-slot:activator="{on}">
            <v-btn block rounded class="primary" v-on="on">Resend Verification Email</v-btn>
          </template>
          <v-card dark>
            <v-card-title>Request new Verification Email</v-card-title>
            <v-card-text>
              <v-text-field
                class="mx-4 mt-4"
              label="E-Mail Address"
              outlined rounded
              v-model="resendEmailVerificationEmail">
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn block class="primary" rounded
              v-on:click="resendEmailVerification"
              >Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog >
      </v-card-actions>
    </v-card>
  </v-layout>

</v-container>
</template>

<script>
import { Buffer } from 'buffer';

// eslint-disable-next-line import/extensions
const logger = require('../../Logger.js');

export default {
  name: 'QuickLinks',
  data() {
    return {
      showResendVerificationEmailDialog: false,
      loading: false,
      success: false,
      error: false,
      resendEmailVerificationEmail: null,
    };
  },
  computed: {
    action() {
      return this.$router.currentRoute.params.action;
    },
    discoveryString() {
      if (this.action === 'email-verification') return this.$router.currentRoute.query.discoveryString;
      return null;
    },
    displayName() {
      if (this.action === 'email-verification') return this.$router.currentRoute.query.displayName;
      return null;
    },
    authenticationId() {
      if (this.action === 'email-verification') return this.$router.currentRoute.query.id;
      return null;
    },
    emailVerificationToken() {
      if (this.action === 'email-verification') return this.$router.currentRoute.query.token;
      return null;
    },
  },
  async mounted() {
    if (this.action === 'email-verification') {
      await this.verifyEmail();
    }
  },
  methods: {
    async resendEmailVerification() {
      const buffer = Buffer.from(this.discoveryString, 'base64');
      const discoveryObject = JSON.parse(buffer.toString('ascii'));
      this.loading = true;
      const result = await this.$store.dispatch('resendEmailVerification', { discoveryObject, email: this.resendEmailVerificationEmail });
      this.loading = false;
      if (result) {
        this.$toast.open({
          message: 'Verification E-Mail sent successfully!',
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: 'Could not send Verification E-Mail please try again later.',
          type: 'error',
          duration: 10000,
        });
      }
    },
    async verifyEmail() {
      if (this.checkDiscoveryObjectValidity(this.discoveryString)) {
        this.loading = true;
        const buffer = Buffer.from(this.discoveryString, 'base64');
        const discoveryObject = JSON.parse(buffer.toString('ascii'));
        const result = await this.$store.dispatch('verifyEmail', { discoveryObject, authenticationId: this.authenticationId, verificationToken: this.emailVerificationToken });
        this.loading = false;
        if (result.successful) {
          this.success = true;
          return this.$router.replace({ name: 'SelectBarn' });
        }
        logger.log('ERROR', `Could not Verify Email because of ${result.error}`);
        this.error = true;
      }
      return undefined;
    },
    checkDiscoveryObjectValidity(input) {
      const buffer = Buffer.from(input, 'base64');
      try {
        const discoveryObject = JSON.parse(buffer.toString('ascii'));

        if (
          !this.checkLocal(discoveryObject)
          && !this.checkRemote(discoveryObject)
          && !this.checkGateway(discoveryObject)
        ) return false;
      } catch {
        return false;
      }
      return true;
    },
    checkLocal(discoveryObject) {
      if (!discoveryObject.local) return false;
      if (!discoveryObject.local.hostname) return false;
      if (!discoveryObject.local.port) return false;
      if (!discoveryObject.local.protocol) return false;
      return true;
    },
    checkRemote(discoveryObject) {
      if (!discoveryObject.remote) return false;
      if (!discoveryObject.remote.hostname) return false;
      if (!discoveryObject.remote.port) return false;
      if (!discoveryObject.remote.protocol) return false;
      return true;
    },
    checkGateway(discoveryObject) {
      if (!discoveryObject.gateway) return false;
      if (!discoveryObject.gateway.hostname) return false;
      if (!discoveryObject.gateway.port) return false;
      if (!discoveryObject.gateway.protocol) return false;
      if (!discoveryObject.gateway.id) return false;
      return true;
    },
  },
};
</script>

<style scoped>

</style>
