<template>

  <v-container class="ma-0" dark>
    <v-menu
      v-model="menuOpened"
      absolute
      :close-on-content-click="false"
      offset-y
      nudge-bottom="32px"
    >
      <template v-slot:activator="{on}">
        <v-text-field
          :disabled="disabled"
          :error-messages="errorMessages"
          ref="textField"
          v-on:keydown="keydown"
          rounded
          outlined
          dark
          v-model="textEntry"
          :hint="inputHint"
          append-icon="calendar_month"
          :append-outer-icon="swapButton"
          v-on:click:append="on.click"
          v-on:click:append-outer="swapCurrentMode"
          v-on:blur="blurTextField"
          :label="label"
        >
        </v-text-field>
      </template>
        <v-card>
            <v-row no-gutters dense>
              <v-col class="shrink">
                <v-date-picker v-model="dateValue" class="mx-2 mt-1" first-day-of-week="1"></v-date-picker>
              </v-col>
              <v-col class="shrink">
                <v-time-picker
                  format="24hr"
                  use-seconds v-model="timeValue" class="mx-2 mt-1"></v-time-picker>
              </v-col>
            </v-row>
          <v-btn block small class="primary" v-on:click="closeMenu">Close</v-btn>
        </v-card>
    </v-menu>
  </v-container>

</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';

export default {
  props: ['value', 'isReset', 'overrideModeSwapButton', 'label', 'disabled'],
  name: 'NewDTPicker',
  data() {
    return {
      textEntry: '',
      openPickerMenu: false,
      dateValue: null,
      timeValue: null,
      errorMessages: [],
      currentMode: 'auto',
      menuOpened: false,
    };
  },
  computed: {
    ...mapGetters(['getDeveloperOptions']),
    swapButton() {
      if (this.overrideModeSwapButton === false) return '';
      if (!this.getDeveloperOptions.showTimestampSwitcher && this.overrideModeSwapButton !== true) return '';
      if (this.currentMode === 'auto') return 'auto_fix_normal';
      if (this.currentMode === 'timestamp') return 'tag';
      if (this.currentMode === 'dateString') return 'diversity_1';
      return '';
    },
    inputHint() {
      if (this.isTimestamp) return 'Unix timestamp';
      return 'YYYY-mm-DD HH:MM:SS';
    },
    isTimestamp() {
      if (this.currentMode === 'timestamp') return true;
      if (this.currentMode === 'dateString') return false;
      return this.textEntry.startsWith('1');
    },
    parsedTextEntry() {
      if (this.isTimestamp) {
        if (this.textEntry) return new Date(parseInt(this.textEntry));
        return new Date(0);
      }
      return new Date(Date.parse(this.textEntry.replace(' ', 'T')));
    },
    dateValueChanged() {
      const builtDateValue = `${this.parsedTextEntry.getFullYear().toString().padStart(4, '0')}-${(this.parsedTextEntry.getMonth() + 1).toString().padStart(2, '0')}-${this.parsedTextEntry.getDate().toString().padStart(2, '0')}`;
      return builtDateValue !== this.dateValue;
    },
    timeValueChanged() {
      const builtTimeValue = this.parsedTextEntry.toTimeString().substring(0, 8);
      return builtTimeValue !== this.timeValue;
    },
    valueNeedsToBeUpdated() {
      return this.parsedTextEntry.getTime() && this.parsedTextEntry.getTime() !== this.value.getTime();
    },
    valueChanged() {
      return this.parsedTextEntry.getTime() !== this.value.getTime();
    },
  },
  mounted() {
    if (this.value) this.updateTextEntryFromValue(); else this.$emit('input', new Date());
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate !== oldDate && newDate !== null) this.changeValue(Date.parse(`${newDate}T${this.time}`));
    },
    time(newTime, oldTime) {
      if (newTime !== oldTime && newTime !== null) this.changeValue(Date.parse(`${this.date}T${newTime}`));
    },
    parsedTextEntry() {
      this.dateValue = `${this.parsedTextEntry.getFullYear().toString().padStart(4, '0')}-${(this.parsedTextEntry.getMonth() + 1).toString().padStart(2, '0')}-${this.parsedTextEntry.getDate().toString().padStart(2, '0')}`;
      this.timeValue = this.parsedTextEntry.toTimeString().substring(0, 8);
      if (this.valueNeedsToBeUpdated) this.updateValue();
    },
    timeValue() {
      if (!this.timeValueChanged) return;
      this.updateTimeFromTimeValue();
    },
    dateValue() {
      if (!this.dateValueChanged) return;
      this.updateDateFromDateValue();
    },
    value() {
      if (!this.valueChanged) return;
      this.updateTextEntryFromValue();
    },
  },
  methods: {
    closeMenu() {
      this.menuOpened = false;
    },
    swapCurrentMode() {
      if (this.currentMode === 'auto') {
        this.updateDateAndTimeFromPickersAsUnixTimestamp();
        this.currentMode = 'timestamp';
        return;
      }
      if (this.currentMode === 'timestamp') {
        this.textEntry = `${this.dateValue} ${this.timeValue}`;
        this.currentMode = 'dateString';
        return;
      }
      if (this.currentMode === 'dateString') {
        this.currentMode = 'auto';
      }
    },
    updateValue() {
      this.$emit('input', this.parsedTextEntry);
    },
    updateTextEntryFromValue() {
      if (this.isTimestamp) {
        this.textEntry = this.value.getTime();
      } else {
        this.textEntry = `${this.value.getFullYear().toString().padStart(4, '0')}-${(this.value.getMonth() + 1).toString().padStart(2, '0')}-${(this.value.getDate()).toString().padStart(2, '0')} ${(this.value.getHours()).toString().padStart(2, '0')}:${(this.value.getMinutes()).toString().padStart(2, '0')}:${(this.value.getSeconds()).toString().padStart(2, '0')}`;
      }
    },
    updateDateAndTimeFromPickersAsUnixTimestamp() {
      const { dateValue, timeValue } = this;
      const [year, month, day] = dateValue.split('-');
      const [hour, minute, second] = timeValue.split(':');
      const date = new Date();
      date.setFullYear(year);
      date.setMonth(month - 1);
      date.setDate(day);
      date.setHours(hour);
      date.setMinutes(minute);
      date.setSeconds(second);
      date.setMilliseconds(0);
      this.textEntry = date.getTime().toString();
    },
    updateTimeFromTimeValue() {
      const { timeValue } = this;
      if (!this.isTimestamp) this.textEntry = `${this.textEntry.split(' ')[0]} ${timeValue}`;
      else this.updateDateAndTimeFromPickersAsUnixTimestamp();
    },
    updateDateFromDateValue() {
      const { dateValue } = this;
      if (!this.isTimestamp) this.textEntry = `${dateValue} ${this.textEntry.split(' ')[1]}`;
      else this.updateDateAndTimeFromPickersAsUnixTimestamp();
    },
    keydown(event) {
      let addedCount = 0;
      const newCharacterAdded = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', ':', ' '].includes(event.key);
      const selectionCount = this.$refs.textField.$refs.input.selectionEnd - this.$refs.textField.$refs.input.selectionStart;
      // first make sure that we're not adding any more characters if we are already at the limit
      if (
        !this.isTimestamp
        && newCharacterAdded
        && this.textEntry.length === 19
        && selectionCount < 1) return event.preventDefault();

      // if the keypress is not an allowed one (so no Control characters, Separators or numbers), stop the event
      if (
        !newCharacterAdded
        && event.ctrlKey !== true
        && !['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'End', ' '].includes(event.key)
      ) return event.preventDefault();
      if (this.isTimestamp) return undefined;

      // make sure separators cannot be overwritten/replaced
      if (this.isTimestamp && newCharacterAdded && selectionCount !== 19) {
        const { selectionStart, selectionEnd } = this.$refs.textField.$refs.input;
        const selected = [];
        // eslint-disable-next-line no-plusplus
        for (let i = selectionStart; i < selectionEnd; i++) {
          selected.push(i);
        }
        for (const selectedElement of selected) {
          if ([4, 7, 10, 13, 16].includes(selectedElement)) {
            return event.preventDefault();
          }
        }
      }

      if (['-', ':', ' '].includes(event.key)) {
        // insert premature separator?
        const { selectionStart } = this.$refs.textField.$refs.input;
        if (selectionStart === 2) {
          this.textEntry = `20${this.textEntry}-`;
          addedCount += 3;
        } else
        if (![4, 7, 10, 13, 16].includes(selectionStart - 1)
          && ![4, 7, 10, 13, 16].includes(selectionStart)
        ) {
          addedCount += 1;
          this.textEntry = `${this.textEntry.substring(0, selectionStart - 1)}0${this.textEntry.substring(selectionStart - 1)}`;
        }

        event.preventDefault();
      }

      // insert a separator if it is needed
      if (newCharacterAdded) this.insertAppropriateSeparator(addedCount);

      return undefined;

      //   return undefined;
    },
    insertAppropriateSeparator(addedCount) {
      const { selectionStart, selectionEnd } = this.$refs.textField.$refs.input;
      let separatorOffset = 0;
      if ([4, 7, 10, 13, 16].includes(selectionStart)) {
        if (selectionStart === 4 || selectionStart === 7) {
          this.textEntry = `${this.textEntry.substring(0, selectionStart)}-${this.textEntry.substring(selectionStart)}`;
          separatorOffset = 1;
        }
        if (selectionStart === 10) {
          this.textEntry = `${this.textEntry.substring(0, selectionStart)} ${this.textEntry.substring(selectionStart)}`;
          separatorOffset = 1;
        }
        if (selectionStart === 13 || selectionStart === 16) {
          this.textEntry = `${this.textEntry.substring(0, selectionStart)}:${this.textEntry.substring(selectionStart)}`;
          separatorOffset = 1;
        }
      }
      this.$nextTick().then(() => {
        this.$refs.textField.$refs.input.selectionStart = selectionStart + separatorOffset + addedCount;
        this.$refs.textField.$refs.input.selectionEnd = selectionEnd + separatorOffset + addedCount;
      });
    },
    blurTextField() {
      if (this.isTimestamp) return;
      const [dateString, timeString] = this.textEntry.split(' ');
      const [year = new Date().getFullYear().toString(), month = '1', day = '1'] = dateString.split('-');
      const [hour = '0', minute = '0', second = '0'] = timeString.split(':');
      this.errorMessages = [];

      if (month.toString() > 12) this.errorMessages.push('Month Invalid!');
      if (month.toString() < 1) this.errorMessages.push('Month Invalid!');

      if (day.toString() > 31) this.errorMessages.push('Day Invalid!');
      if (day.toString() < 1) this.errorMessages.push('Day Invalid!');

      if (hour.toString() > 24) this.errorMessages.push('Hours Invalid!');
      if (hour.toString() < 0) this.errorMessages.push('Hours Invalid!');

      if (minute.toString() > 59) this.errorMessages.push('Minutes Invalid!');
      if (minute.toString() < 0) this.errorMessages.push('Minutes Invalid!');

      if (second.toString() > 59) this.errorMessages.push('Seconds Invalid!');
      if (second.toString() < 0) this.errorMessages.push('Seconds Invalid!');

      let newTextEntry = '';
      if (year.length === 2) newTextEntry = `${newTextEntry}20${year}`; else newTextEntry += year;
      newTextEntry += '-';
      newTextEntry += month.padStart(2, '0');
      newTextEntry += '-';
      newTextEntry += day.padStart(2, '0');
      newTextEntry += ' ';
      newTextEntry += hour.padStart(2, '0');
      newTextEntry += ':';
      newTextEntry += minute.padStart(2, '0');
      newTextEntry += ':';
      newTextEntry += second.padStart(2, '0');
      this.textEntry = newTextEntry;
    },
  },
};
</script>

<style scoped></style>
