const Logger = {
  messages: [],
  log(level, message) {
    Logger.messages.push({ time: Date.now(), level, message });
  },
  getAllMessages: () => Logger.messages,
  getFormattedMessages: () => Logger.messages.map((message) => {
    let output = new Date(message.time).toISOString();
    output += ' | ';
    output += `${message.level} | `;
    output += message.message;
    return output;
  }),
};

module.exports = Logger;
