import Api from '../../config/Api/API';

const logger = require('@/Logger');

const state = {
  cameras: {},
  stillFrames: {},
  blockedThumbnailLoadings: {},
  fallbackFrames: {},
};

const getters = {
  getFallbackFrame: (state) => (cameraId) => state.fallbackFrames[cameraId],
  cameraName: (state, getters) => (id) => getters.getCamera(id)?.name,
  filterCameras: (state, getters) => (searchString) => Object.entries(state.cameras).map(([cameraId, camera]) => Object.assign(camera, { cameraId }))
    .filter(
      (camera) => camera
          && (getters.alsoShowNoTagCameras || camera.tags.length !== 0) && !camera.tags.includes('decommissioned') && (searchString === undefined || !camera.name || camera.name.toLowerCase().includes(searchString.toLowerCase())
            || camera.cameraId.toLowerCase().includes(searchString.toLowerCase())),
    )
    .sort((a, b) => {
      if (a.sortPrefix + a.name < b.sortPrefix + b.name) return -1;
      if (a.sortPrefix + b.sortPrefix + a.name > b.name) return 1;
      return 0;
    }),
  getCameras: (state) => state.cameras,
  getCamera: (state) => (id) => state.cameras[id],
  getStillFrame: (state) => (id) => state.stillFrames[id],
  getStillFrames: (state) => state.stillFrames,
  thumbnailLoadingBlocked: (state) => (cameraId) => (
    state.blockedThumbnailLoadings
      && state.blockedThumbnailLoadings[cameraId] === true
  ),
};
const mutations = {
  blockThumbnailLoading(state, cameraId) {
    state.blockedThumbnailLoadings[cameraId] = true;
  },
  unblockThumbnailLoading(state, cameraId) {
    state.blockedThumbnailLoadings[cameraId] = false;
  },
  setStillFrameToStale(state, cameraId) {
    state.stillFrames[cameraId] = {
      ...state.stillFrames[cameraId],
      stale: true,
    };
  },
  addCamera(state, data) {
    // this._vm.$set(state.cameras, [data.id], data.camera);
    state.cameras = {
      ...state.cameras,
      [data.id]: { ...data.camera },
    };
  },

  setFallbackFrame(state, { cameraId, frame }) {
    state.fallbackFrames = {
      ...state.fallbackFrames,
      [cameraId]: {
        ...{
          timestamp: Date.now(),
          frame,
        },
      },
    };
  },

  setStillFrames(state, data) {
    state.stillFrames = {
      ...state.stillFrames,
      [data.cameraId]: {
        ...{
          timestamp: Date.now(),
          frame: data.frame,
          stale: false,
          ySize: data.ySize,
        },
      },
    };
  },

  clearCameras(state) {
    state.cameras = {};
  },
};
const actions = {

  async enablePrivacyForAllCameras(_, { globalUniqueIdentifier = undefined, duration }) {
    const result = await Api.put({
      globalUniqueIdentifier,
      path: `api/privacy/multiple/${duration}`,
      payload: {
        allCameras: true,
      },
    });
    return result.successful;
  },

  async disablePrivacyForAllCameras(_, { globalUniqueIdentifier = undefined } = {}) {
    const result = await Api.put({
      globalUniqueIdentifier,
      path: 'api/privacy/multiple/false',
      payload: {
        allCameras: true,
      },
    });
    return result.successful;
  },

  // eslint-disable-next-line no-unused-vars
  async getFrameForTimestamp({ commit, getters }, {
    cameraIds, timestamp, resizeY = 0, abortSignal = null,
  }) {
    if (getters.getDeveloperOptions.disableMediaLoading) return undefined;
    const frames = [];
    const promises = cameraIds.map(
      (cameraId) => new Promise((resolve) => {
        const endpoint = `api/recordings/frame/${cameraId}/${timestamp}`;
        Api.get({
          path: endpoint, responseType: 'blob', params: { resizeY: resizeY === 0 ? undefined : resizeY }, signal: abortSignal,
        })
          .then((r) => {
            if (r.successful) {
              frames.push({
                cameraId,
                result: URL.createObjectURL(r.response.data),
                successful: true,
                timestamp,
              });
              resolve();
            } else {
              frames.push({
                cameraId,
                result: r.error.response.status,
                successful: false,
                timestamp,
              });
              resolve();
            }
          })
          .catch(() => {
            frames.push({
              cameraId,
              result: null,
              successful: false,
              timestamp,
            });
            resolve();
          });
      }),
    );
    await Promise.allSettled(promises);
    return frames;
  },

  async getSpecificCamera({ commit, dispatch }, cameraId) {
    try {
      const endpoint = `api/cameras/${cameraId}`;
      const { response } = await Api.get({ path: endpoint });
      const camera = response.data;
      camera.cameraId = cameraId;
      commit('addCamera', { id: cameraId, camera });
      dispatch('loadThumbnail', { cameraId, force: true });
      return response;
    } catch (error) {
      logger.log('Error', `Could not Fetch Specific Camera ${cameraId} because of ${error.stack}`);
      return error;
    }
  },
  async getCameras({ commit, dispatch }, settings) {
    let blockThumbnailLoading = false;
    if (settings && settings.blockThumbnailLoading) blockThumbnailLoading = true;
    try {
      const endpoint = 'api/cameras';
      const response = await Api.get({ path: endpoint });
      const loadedTimestamp = Date.now();
      if (!response.successful) return undefined;
      commit('clearCameras');
      Object.entries(response.response.data.cameras).forEach(([id, camera]) => {
        // eslint-disable-next-line no-param-reassign
        camera.cameraId = id;
        // eslint-disable-next-line no-param-reassign
        camera.loadedTimestamp = loadedTimestamp;
        commit('addCamera', { id, camera });
        if (!blockThumbnailLoading && !camera.tags.includes('decommissioned')) dispatch('loadThumbnail', { cameraId: id, force: true, resizeY: 75 });
      });
      return response.response;
    } catch (error) {
      logger.log('Error', `Could not Fetch Cameras because of ${error.stack}`);
      return error;
    }
  },
  async loadThumbnail({ getters, commit }, {
    cameraId, force, resizeY = undefined, abortSignal = null,
  }) {
    if (getters.getDeveloperOptions.disableMediaLoading) return;
    const annotate = getters.getDeveloperOptions.annotateStillFrames;
    const cameraRecord = getters.getCamera(cameraId);
    const stillFrame = getters.getStillFrame(cameraId);
    if (!cameraRecord) {
      return;
    }
    if (force !== true) {
      if (getters.thumbnailLoadingBlocked(cameraId)) return;
      if (
        stillFrame
        && Date.now() - stillFrame.timestamp <= 1000 * 60 * 5
        && !stillFrame.stale
        && (stillFrame.ySize >= resizeY && resizeY !== undefined)
      ) {
        return;
      }
    }
    commit('blockThumbnailLoading', cameraId);
    commit('setStillFrameToStale', cameraId);
    let endpoint = `api/cameras/${cameraId}/still`;
    if (annotate) endpoint += '?draw=dot';
    const r = await Api.get({
      path: endpoint, responseType: 'blob', params: { resizeY }, signal: abortSignal,
    });
    if (r.successful) {
      const { response } = r;
      if (response.status === 200) {
        const blobframe = URL.createObjectURL(response.data);
        commit('setStillFrames', {
          cameraId,
          frame: blobframe,
          ySize: resizeY,
        });
      }
    }
    commit('unblockThumbnailLoading', cameraId);
  },
  async loadFallbackThumbnail(
    { getters, commit, dispatch },
    { cameraId, force, abortSignal },
  ) {
    const fallbackFrame = getters.getFallbackFrame(cameraId);
    if (!fallbackFrame || force) {
      if (getters.getDeveloperOptions.disableMediaLoading) return;
      const endpoint = `api/cameras/${cameraId}/still?forceFallback=true`;
      const r = await Api.get({ path: endpoint, responseType: 'blob', signal: abortSignal });
      if (r.successful) {
        const { response } = r;
        const blobframe = URL.createObjectURL(response.data);
        commit('setFallbackFrame', {
          cameraId,
          frame: blobframe,
        });
      } else if (r.error.response.status === 404) {
        await dispatch('loadThumbnail', {
          cameraId,
          force: true,
        });
      }
    }
  },

  async reloadThumbnails({ getters, dispatch }) {
    if (getters.getDeveloperOptions.disableMediaLoading) return;
    const promises = [];
    Object.keys(getters.getCameras).forEach((key) => {
      promises.push(dispatch('loadThumbnail', { cameraId: key }));
    });
    await Promise.all(promises);
  },

  async createNewCamera(_, payload) {
    try {
      const endpoint = 'api/cameras';
      const { response } = await Api.post({ path: endpoint, payload });
      return response;
    } catch (error) {
      logger.log('Error', `Could not create new Camera with Payload ${JSON.stringify(payload)} because of ${error.stack}`);
      return error;
    }
  },

  async updateCamera(_, payload) {
    try {
      const endpoint = `api/cameras/${payload.key}/${payload.cameraId}`;
      const { response } = await Api.patch({
        path: endpoint,
        payload: {
          cameraRecord: payload.cameraRecord,
        },
      });
      return response;
    } catch (error) {
      logger.log('Error', `Could not update Camera with Payload ${JSON.stringify(payload)} because of ${error.stack}`);
      return error;
    }
  },

  async changeStatus(_, payload) {
    try {
      const endpoint = `api/cameras/${payload.cameraId}/management/${payload.autoManaged}`;
      const { response } = await Api.put({ path: endpoint, payload: null });
      return response;
    } catch (error) {
      logger.log('Error', `Could not change Management Status of camera with Payload ${JSON.stringify(payload)} because of ${error.stack}`);
      return error;
    }
  },

  async deleteCamera(_, payload) {
    try {
      const endpoint = `api/cameras/${payload.cameraId}`;
      const { response } = await Api.delete({ path: endpoint });

      return response;
    } catch (error) {
      logger.log('Error', `Could not delete camera ${payload.cameraId} because of ${error.stack}`);
      return error;
    }
  },

  async privacyToggle({ dispatch }, { cameraId, privacy }) {
    try {
      const endpoint = `api/privacy/${cameraId}/${privacy}`;
      const { response } = await Api.put({ path: endpoint, payload: null });
      if (response.status === 200) {
        await dispatch('getSpecificCamera', cameraId);
      }
      return response;
    } catch (error) {
      logger.log('Error', `Could not toggle privacy to ${privacy} for camera ${cameraId} because of ${error.stack}`);
      return error;
    }
  },

  async liveStream(_, payload) {
    try {
      const endpoint = `api/streaming/${payload.cameraId}`;
      const { response } = await Api.get({ path: endpoint });
      return response;
    } catch (error) {
      logger.log('Error', `Could not start livestream of camera ${payload.cameraId} because of ${error.stack}`);
      return error;
    }
  },

  async markAsFallbackFrame(_, { cameraId, timestamp }) {
    try {
      const endpoint = `api/recordings/markAsFallback/${cameraId}/${timestamp}`;
      const { response } = await Api.post({ path: endpoint });
      return response;
    } catch (error) {
      logger.log('Error', `Could not mark timestamp ${timestamp} as fallback frame for camera ${cameraId} because of ${error.stack}`);
      return error;
    }
  },
  async loadRecordings(_, payload) {
    try {
      const endpoint = 'api/recordings/query';
      return (await Api.post({ path: endpoint, payload })).response
        .data;
    } catch (error) {
      logger.log('Error', `Could not load recordings with query ${payload.query} because of ${error.stack}`);
      return error;
    }
  },
  async loadGifForRecording({ getters }, recordingId) {
    if (getters.getDeveloperOptions.disableMediaLoading) return undefined;

    try {
      const endpoint = `api/recordings/thumbnail/gif/${recordingId}`;
      return URL.createObjectURL(
        (await Api.get({ path: endpoint, responseType: 'blob' })).response.data,
      );
    } catch (error) {
      logger.log('Error', `Could not load recording-gif for recordingId ${recordingId} because of ${error.stack}`);
      return error;
    }
  },
  async loadThumbnailForRecording({ getters }, recordingId) {
    if (getters.getDeveloperOptions.disableMediaLoading) return undefined;
    try {
      const endpoint = `api/recordings/thumbnail/image/${recordingId}`;
      return URL.createObjectURL(
        (await Api.get({ path: endpoint, responseType: 'blob' })).response.data,
      );
    } catch (error) {
      logger.log('Error', `Could not load thumbnail for recordingId ${recordingId} because of ${error.stack}`);
      return error;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
