import Api from '../../config/Api/API';

const state = {
  canvases: {},
  baseCanvas: null,
};

const getters = {
  getCanvases: (state) => state.canvases,
  getBaseCanvas: (state) => state.baseCanvas,
};
const mutations = {
  setCanvases(state, canvases) {
    state.canvases = Object.fromEntries(canvases.map((canvas) => [canvas.cameraCanvasId, canvas]));
  },
  setBaseCanvas(state, baseCanvas) {
    state.baseCanvas = baseCanvas;
  },
};
const actions = {
  async fetchCanvases({ commit }) {
    commit('setCanvases', []);
    const endpoint = 'api/cameraCanvases/query';
    const response = await Api.post({ path: endpoint });
    if (!response.successful) return;
    commit('setCanvases', response.response.data);
  },
  async getSpecificCanvas(_, canvasId) {
    const endpoint = 'api/cameraCanvases/queryOne';
    const response = await Api.post({ path: endpoint, payload: { query: [canvasId] } });
    if (!response.successful) return false;
    return response.response.data;
  },
  async fetchBaseCanvas({ commit }) {
    const endpoint = 'api/cameraCanvases/';
    const response = await Api.get({ path: endpoint });
    if (!response.successful) {
      commit('setBaseCanvas', null);
      return;
    }
    commit('setBaseCanvas', response.response.data);
  },
  async createNewCanvas(_, {
    name, published, editable, heightRatio, cameras,
  }) {
    const endpoint = 'api/cameraCanvases';
    const response = await Api.post({
      path: endpoint,
      payload: {
        cameraCanvas: {
          name, published, editable, heightRatio, cameras,
        },
      },
    });
    if (!response.successful) {
      if (response.error.response.status === 409) return 409;
      return false;
    }
    return true;
  },
  async updateCanvas(_, { canvasId, canvas }) {
    const endpoint = `api/cameraCanvases/${canvasId}`;
    const update = { };
    if (Object.keys(canvas).includes('name')) update.name = canvas.name;
    if (Object.keys(canvas).includes('published')) update.published = canvas.published;
    if (Object.keys(canvas).includes('editable')) update.editable = canvas.editable;
    if (Object.keys(canvas).includes('heightRatio')) update.heightRatio = canvas.heightRatio;
    if (Object.keys(canvas).includes('cameras')) update.cameras = canvas.cameras;
    const response = await Api.patch({ path: endpoint, payload: { updates: update } });
    if (!response.successful) {
      if (response.error.response.status === 409) return 409;
      return false;
    }
    return true;
  },
  async markAsBase(_, canvasId) {
    const endpoint = 'api/config/pig-brother.camera.canvas.management/';
    await Api.patch({
      path: endpoint,
      payload: {
        doc: { baseCanvas: canvasId },
      },
    });
  },
  async unmarkAsBase() {
    const endpoint = 'api/config/pig-brother.camera.canvas.management/';
    await Api.patch({
      path: endpoint,
      payload: {
        doc: { baseCanvas: { meta: 'unsetIgnoreDefault' } },
      },
    });
  },
  async deleteCanvas(_, canvasId) {
    const endpoint = `api/cameraCanvases/${canvasId}`;
    await Api.delete({ path: endpoint });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
