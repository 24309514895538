<template>
  <v-container class="ma-0 pa-0" fluid>
  <BarnDocumentationIncident
    v-if="incident.type === 'barnDocumentation'"
    :incident="incident"
    :events="events"
    :enable-more-button="enableMoreButton"
    :enable-delete-incident-button="enableDeleteIncidentButton"
    :enable-close-incident-button="enableCloseIncidentButton"
    :enable-trigger-incident-button="enableTriggerIncidentButton"
    v-on:reload="reload"
  ></BarnDocumentationIncident>
  <BarnInterventionIncident
  v-else-if="incident.type === 'barnIntervention'"
  :incident="incident"
  :events="events"
  :enable-more-button="enableMoreButton"
  :enable-delete-incident-button="enableDeleteIncidentButton"
  :enable-close-incident-button="enableCloseIncidentButton"
  :enable-trigger-incident-button="enableTriggerIncidentButton"
  v-on:reload="reload">
  </BarnInterventionIncident>
  <GenericIncident
    v-else
  :incident="incident"
  :enable-more-button="enableMoreButton"
  :enable-delete-incident-button="enableDeleteIncidentButton"
  :enable-close-incident-button="enableCloseIncidentButton"
  :enable-trigger-incident-button="enableTriggerIncidentButton"
  v-on:reload="reload"
  ></GenericIncident>
</v-container>
</template>

<script>
import GenericIncident from '@/components/Incidents/GenericIncident.vue';
import BarnInterventionIncident from '@/components/Incidents/BarnInterventionIncident.vue';
import BarnDocumentationIncident from '@/components/Incidents/BarnDocumentationIncident.vue';

export default {
  name: 'AbstractIncident',
  components: { BarnInterventionIncident, GenericIncident, BarnDocumentationIncident },
  props: {
    events: Array,
    incident: Object,
    enableMoreButton: Boolean,
    enableDeleteIncidentButton: Boolean,
    enableCloseIncidentButton: Boolean,
    enableTriggerIncidentButton: Boolean,
  },
  methods: {
    async reload() {
      return this.$emit('reload');
    },
  },
};
</script>

<style scoped>

</style>
