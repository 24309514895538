<template>
<v-card dark elevation="8" rounded class="my-2 mx-1 rounded">
<v-card-title>
  {{$t('incident.'+incident.type+'.name')}} from {{humanReadableTimestamp(incident.openedAt)}}
</v-card-title>
  <v-card-text>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Status
          </v-list-item-title>
          <v-list-item-subtitle>{{incident.state}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Type</v-list-item-title>
          <v-list-item-subtitle>{{$t('incident.'+incident.type+'.name')}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Opened At</v-list-item-title>
          <v-list-item-subtitle>{{humanReadableTimestamp(incident.openedAt)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Last Update</v-list-item-title>
          <v-list-item-subtitle>{{humanReadableTimestamp(incident.lastUpdated)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="incident.messagePayload">
        <v-list-item-content>
          <v-list-item-title>Payload</v-list-item-title>
          <v-card flat class="ma-0 pa-0">
            <v-card-text class="ma-0 pa-0">
              <pre>{{JSON.stringify(incident.messagePayload, null, 2)}}</pre>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
  <v-card-actions>
    <v-row justify="end" dense>
      <v-col v-if="canTriggerIncidentEvent && incident.state !== 'closed'" class="shrink">
        <v-dialog v-model="triggerEventDialog" max-width="800px">
          <template v-slot:activator="{on}">
            <v-btn class="mx-4 primary" rounded v-on="on">New Event for this Incident</v-btn>
          </template>
          <IncidentEventTriggerDialog v-on:triggerevent="triggerEvent" :incident="incident" :incident-events="events"></IncidentEventTriggerDialog>
        </v-dialog>
      </v-col>
      <v-col v-if="enableMoreButton" class="shrink">
        <v-btn
          class="primary"
          v-on:click="$router.push({name: 'Incident',
                       params: {incidentId: incident.incidentId}})">
          More
        </v-btn>
      </v-col>
      <v-col v-if="canDeleteIncident " class="shrink">
        <v-dialog v-model="deleteIncidentDialog" max-width="800px">
          <template v-slot:activator="{on}">
            <v-btn class="mx-4 error" rounded v-on="on">Delete Incident</v-btn>
          </template>
          <v-card dark>
            <v-card-title>Delete Incident</v-card-title>
            <v-card-text>Are you sure you want to delete this incident? You won't be able to restore it afterwards.</v-card-text>
            <v-card-actions>
              <v-layout justify-end>
                <v-btn class="mx-4 error rounded" v-on:click="deleteIncidentDialog = false">Cancel</v-btn>
                <v-btn class="mx-4 primary rounded" v-on:click="deleteIncident">Confirm</v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="canCloseIncident && incident.state !== 'closed'" class="shrink">
        <v-dialog v-model="closeDialog" max-width="800px">
          <template v-slot:activator="{on}">
            <v-btn class="mx-4 success--text" rounded v-on="on">Close Incident</v-btn>
          </template>
          <v-card dark>
            <v-card-title>Close Incident</v-card-title>
            <v-card-text>Are you sure you want to close this incident? You won't be able to edit it afterwards.</v-card-text>
            <v-card-actions>
                <v-layout justify-end>
                  <v-btn class="mx-4 error rounded" v-on:click="closeDialog = false">Cancel</v-btn>
                  <v-btn class="mx-4 primary rounded" v-on:click="closeIncident">Confirm</v-btn>
                </v-layout>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card-actions>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import IncidentEventTriggerDialog from '@/components/Incidents/IncidentEventTriggerDialog.vue';

export default {
  components: { IncidentEventTriggerDialog },
  props: {
    incident: Object,
    enableMoreButton: Boolean,
    enableDeleteIncidentButton: Boolean,
    enableCloseIncidentButton: Boolean,
    enableTriggerIncidentButton: Boolean,
  },
  name: 'GenericIncident',
  data() {
    return {
      closeDialog: false,
      deleteIncidentDialog: false,
      triggerEventDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasAllPermissions',
    ]),
    canCloseIncident() {
      if (!this.enableCloseIncidentButton) return false;
      if (this.incident.type === 'barnState') return false;
      if (this.hasAllPermissions(`incidents.${this.incident.type}.manage`)) return true;
      if (this.hasAllPermissions('incidents.manage.advanced')) return true;
      if (this.hasAllPermissions('incidents.manage')) return true;
      return false;
    },
    canDeleteIncident() {
      if (!this.enableDeleteIncidentButton) return false;
      if (this.hasAllPermissions('incidents.manage.advanced')) return true;
      return false;
    },
    canTriggerIncidentEvent() {
      if (!this.enableTriggerIncidentButton) return false;
      if (this.incident.type !== 'barnIntervention') return false;
      if (this.hasAllPermissions(`incidents.${this.incident.type}.manage`)) return true;
      if (this.hasAllPermissions('incidents.manage.advanced')) return true;
      if (this.hasAllPermissions('incidents.manage')) return true;
      return false;
    },
  },
  methods: {
    async closeIncident() {
      await this.$store.dispatch('closeIncident', { incidentId: this.incident.incidentId });
      this.closeDialog = false;
      this.$emit('reload');
    },
    async deleteIncident() {
      await this.$store.dispatch('deleteIncident', { incidentId: this.incident.incidentId });
      this.deleteIncidentDialog = false;
      this.$emit('reload');
    },
    async triggerEvent({
      identifier, type, trigger, payload,
    }) {
      await this.$store.dispatch('triggerIncidentEvent', {
        identifier, type, trigger, payload,
      });
      this.triggerEventDialog = false;
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>

</style>
