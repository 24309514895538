/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import Api from '../../config/Api/API';
// eslint-disable-next-line import/no-cycle
import router from '../../router';

const logger = require('@/Logger');
const { Buffer } = require('buffer');

const getDefaultState = () => ({

});

const state = getDefaultState();

const getters = {
};

const mutations = {
};

const actions = {

  async loadAllServiceAccounts({ rootGetters }, { globalUniqueIdentifier = undefined } = {}) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: 'api/service_accounts/all', globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },
  async loadOwnServiceAccounts({ rootGetters }, { globalUniqueIdentifier = undefined } = {}) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: 'api/service_accounts/own', globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },
  async loadOwnServiceAccount({ rootGetters }, { globalUniqueIdentifier = undefined, serviceAccountId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/service_accounts/own/${serviceAccountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },
  async loadAllServiceAccount({ rootGetters }, { globalUniqueIdentifier = undefined, serviceAccountId }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/service_accounts/all/${serviceAccountId}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },
  async deletePublicKeyForOwnServiceAccount({ rootGetters }, { globalUniqueIdentifier = undefined, serviceAccountId, fingerprint }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.delete({ path: `api/service_accounts/own/${serviceAccountId}/pubkeys/${fingerprint}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },
  async deletePublicKeyForAllServiceAccount({ rootGetters }, { globalUniqueIdentifier = undefined, serviceAccountId, fingerprint }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.delete({ path: `api/service_accounts/all/${serviceAccountId}/pubkeys/${fingerprint}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    return response.successful;
  },

  async addPublicKeyToOwnServiceAccount({ rootGetters }, {
    globalUniqueIdentifier = undefined, serviceAccountId, expiry, publicKey,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.put({
      path: `api/service_accounts/own/${serviceAccountId}/pubkeys`,
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      payload: { publicKey, expiry },
    });
    return response.successful;
  },

  async addPublicKeyToAllServiceAccount({ rootGetters }, {
    globalUniqueIdentifier = undefined, serviceAccountId, expiry, publicKey,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.put({
      path: `api/service_accounts/all/${serviceAccountId}/pubkeys`,
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      payload: { publicKey, expiry },
    });
    return response.successful;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
