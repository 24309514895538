<template>
<v-container class="fill-height" fluid>
  <v-layout justify-center align-center>
    <v-flex shrink>
      <v-card dark width="auto" shaped elevation="10" class="pa-4" :loading="loading" :disabled="loading">
               <v-card-title class="text-sm-h4">Authenticate At<strong class="mx-2">{{targetBackendInstance.name}}</strong> via Single Sign On</v-card-title>
        <v-card-text>
          <v-container v-if="!isLoggedInIntoSenator" class="ma-0 pa-0">
            <v-card>
              <v-card-title>First, Login or Register at the Single-Sign-On Server</v-card-title>
              <v-card-text>

                <v-tabs v-model="tabs" grow>
                  <v-tab value="login">Login</v-tab>
                  <v-tab value="register">Register</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs" dark>

                  <v-tab-item class="my-4">
                    <v-form ref="loginForm" v-model="loginFormValid">
                      <v-text-field :rules="loginEmailRules" label="E-Mail Address" solo outlined rounded type="email" v-model="loginEmail"></v-text-field>
                      <v-text-field :rules="loginPasswordRules" label="Password"
                                    solo
                                    outlined
                                    rounded
                                    v-model="loginPassword"
                                    :type="showLoginPassword ? 'text' : 'password'"
                                    :append-icon="showLoginPassword ? 'visibility_off' : 'visibility'"
                                    v-on:click:append="showLoginPassword= !showLoginPassword"
                                    v-on:keyup.enter="signIn"
                      >
                      </v-text-field>
                    </v-form>

                    <v-card-actions>
                      <v-btn block class="primary" rounded v-on:click="signIn">Sign In</v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item class="my-4">
                    <v-form ref="registerForm" v-model="registerFormValid">
                      <v-text-field
                        label="Display Name (Optional)"
                        solo
                        outlined
                        rounded
                        v-model="registerDisplayName"
                      ></v-text-field>
                      <v-text-field
                        :rules="registerEmailRules"
                        label="E-Mail Address"
                        solo
                        outlined
                        rounded
                        v-model="registerEmail"
                        type="email"
                      ></v-text-field>
                      <v-text-field
                        :rules="registerPasswordRules"
                        label="Password"
                        solo
                        outlined
                        rounded
                        v-model="registerPassword"
                        :type="showRegisterPassword ? 'text' : 'password'"
                        :append-icon="showRegisterPassword ? 'visibility_off' : 'visibility'"
                        v-on:click:append="showRegisterPassword= !showRegisterPassword"
                      ></v-text-field>
                      <v-text-field
                        label="Password Repetition"
                        solo
                        outlined
                        rounded
                        v-model="registerPasswordRepetition"
                        :rules="registerPasswordRepetitionRules"
                        :type="showRegisterPasswordRepetition ? 'text' : 'password'"
                        :append-icon="showRegisterPasswordRepetition ? 'visibility_off' : 'visibility'"
                        v-on:click:append="showRegisterPasswordRepetition= !showRegisterPasswordRepetition"
                      ></v-text-field>

                          <v-checkbox :rules="eulaRules" v-if="neededEULA">
                            <template v-slot:label >
                              I have read and agree to the&nbsp; <a @click.stop :href="currentEULA" target="_blank"> EULA </a>
                            </template>
                          </v-checkbox>
                    </v-form>
                    <v-card-actions>
                      <v-btn block class="primary" rounded v-on:click="signUp">Register</v-btn>
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs-items>

              </v-card-text>
            </v-card>

          </v-container>
          <v-container v-if="isLoggedInIntoSenator && doesAccountAtServiceProviderAlreadyExist" class="ma-0 pa-0">
            <v-btn class="ma-4 primary" block rounded v-on:click="loginAtServiceProvider" :loading="signInViaSSOLoading" :disabled="signInViaSSOLoading">Login at {{targetBackendInstance.name}}</v-btn>
          </v-container>
          <v-container v-if="isLoggedInIntoSenator && !doesAccountAtServiceProviderAlreadyExist" class="ma-0 pa-0">
            <v-card>
              <v-card-title tile dark>
                Create an Account at {{targetBackendInstance.name}}
              </v-card-title>
              <v-card-text>
                <v-form ref="createAccountForm" v-model="createAccountFormValid">
                  <v-text-field label="Display Name" v-model="createAccountDisplayName" solo outlined rounded :rules="createAccountDisplayNameRules"></v-text-field>
                  <v-checkbox :rules="eulaRules" v-if="targetInstanceNeedsEula">
                    <template v-slot:label >
                      I have read and agree to the&nbsp; <a @click.stop :href="currentTargetInstanceEula" target="_blank"> EULA </a>
                    </template>
                  </v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn rounded class="primary" block v-on:click="createAccountAtServiceProvider">Create Account at {{targetBackendInstance.name}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn x-small text v-on:click="goToBarnSelection">Go Back to Barn Selection</v-btn>
            <v-btn v-if="isLoggedInIntoSenator" x-small v-on:click="logout" text>Logout of Single-Sign-On Server</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>

</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SSO',
  data() {
    return {
      loading: false,
      tabs: null,
      eulaRules: [
        (value) => value === true || this.$t('validation_rules.required'),
      ],
      loginEmailRules: [
        (value) => (value !== null && !!value) || this.$t('validation_rules.required'),
        (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.$t('validation_rules.email_invalid'),
      ],
      registerEmailRules: [
        (value) => (value !== null && !!value) || this.$t('validation_rules.required'),
        (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.$t('validation_rules.email_invalid'),
      ],
      loginPasswordRules: [(value) => (value !== null && !!value) || this.$t('validation_rules.required')],
      registerPasswordRules: [
        (value) => (value !== null && !!value) || this.$t('validation_rules.required'),
        (value) => (value === null || !value || value.length >= 8) || this.$t('validation_rules.password_too_short'),
        (value) => (value === null || !value || value.length <= 70) || this.$t('validation_rules.password_too_long'),
        (value) => /(?=.*[a-z])/.test(value) || this.$t('validation_rules.password_no_lowercase'),
        (value) => /(?=.*[A-Z])/.test(value) || this.$t('validation_rules.password_no_uppercase'),
        (value) => /(?=.*\d)/.test(value) || this.$t('validation_rules.password_no_numbers'),
        (value) => /(?=.*[@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~])/.test(value) || this.$t('validation_rules.password_no_special_characters'),
        (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~])[A-Za-z\d@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~]{8,70}$/.test(value) || this.$t('validation_rules.password_invalid'),
      ],
      registerPasswordRepetitionRules: [
        (value) => (value !== null && !!value) || this.$t('validation_rules.required'),
        (value) => value === this.registerPassword || this.$t('validation_rules.password_repetition_no_match'),
      ],
      registerDisplayNameRules: [
        (value) => (!!value || value === null || value.length <= 30) || this.$t('validation_rules.display_name_too_long'),
      ],
      createAccountDisplayNameRules: [
        (value) => (value !== null && !!value) || this.$t('validation_rules.required'),
        (value) => (value === null || !value || value.length <= 30) || this.$t('validation_rules.display_name_too_long'),
      ],
      loginPassword: null,
      loginEmail: null,
      showLoginPassword: false,
      showRegisterPassword: false,
      showRegisterPasswordRepetition: false,
      loginFormValid: null,
      registerFormValid: null,
      registerPassword: null,
      registerPasswordRepetition: null,
      registerEmail: null,
      registerDisplayName: null,
      currentEULA: null,
      eulaVersions: null,
      eulaConfirmation: false,
      targetInstanceEulaConfirmation: false,
      serviceProviders: null,
      createAccountFormValid: null,
      createAccountDisplayName: null,
      targetInstanceEulaVersions: null,
      currentTargetInstanceEula: null,
      signInViaSSOLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'getBackendInstance',
      'isLoggedInIntoInstance',
      'getSenator',
    ]),
    serviceProvider() {
      if (!this.serviceProviders) return null;
      return this.serviceProviders.find((serviceProvider) => serviceProvider.globalUniqueIdentifier === this.$route.params.targetInstance);
    },
    doesServiceProviderAlreadyExist() {
      if (!this.isLoggedInIntoSenator) return false;
      return (!!this.serviceProvider);
    },
    doesAccountAtServiceProviderAlreadyExist() {
      if (!this.doesServiceProviderAlreadyExist) return false;
      const { serviceProvider } = this;
      return (!!serviceProvider.remoteAccountId && !!serviceProvider.remoteAuthenticationId);
    },
    isLoggedInIntoSenator() {
      return (this.isLoggedInIntoInstance(this.getSenator.globalUniqueIdentifier));
    },
    targetBackendInstance() {
      return this.getBackendInstance(this.$route.params.targetInstance);
    },
    humanReadableAction() {
      if (this.$route.params.mode === 'login') return 'Sign In into';
      return 'Register at';
    },
    neededEULA() {
      if (!this.eulaVersions) return null;
      return this.eulaVersions[this.eulaVersions.length - 1];
    },
    targetInstanceNeedsEula() {
      if (!this.targetInstanceEulaVersions) return null;
      return this.targetInstanceEulaVersions[this.targetInstanceEulaVersions.length - 1];
    },
  },

  async mounted() {
    this.loading = true;
    this.ready = false;
    await this.$store.dispatch('checkConnectionStrategy', this.getSenator.globalUniqueIdentifier);
    if (this.isLoggedInIntoSenator) await this.reloadServiceProviders();
    this.eulaVersions = await this.$store.dispatch('getEulaVersions', this.getSenator.globalUniqueIdentifier);
    if (this.eulaVersions.length !== 0) this.currentEULA = URL.createObjectURL(await this.$store.dispatch('getCurrentEULA', this.getSenator.globalUniqueIdentifier));

    this.targetInstanceEulaVersions = await this.$store.dispatch('getEulaVersions', this.$route.params.targetInstance);
    if (this.targetInstanceEulaVersions.length !== 0) this.currentTargetInstanceEula = URL.createObjectURL(await this.$store.dispatch('getCurrentEULA', this.$route.params.targetInstance));
    this.loading = false;
    this.ready = true;
  },

  methods: {
    async signIn() {
      this.$refs.loginForm.validate();
      if (!this.loginFormValid) return;
      this.loginLoading = true;
      this.loading = true;
      const successful = await this.$store.dispatch('loginWithEmailAndPassword', {
        globalUniqueIdentifier: this.getSenator.globalUniqueIdentifier,
        email: this.loginEmail,
        password: this.loginPassword,
      });
      this.loginPassword = null;
      if (successful !== true) {
        if (successful === 'email-not-verified') {
          this.$toast.open({ message: 'Please verify your E-Mail First!', type: 'error' });
        } else this.$toast.open({ message: 'Login Unsuccessful!', type: 'error' });
      } else {
        this.$toast.open('Successfully Logged In!');
        await this.reloadServiceProviders();
      }
      this.loginLoading = false;
      this.loading = false;
    },

    async signUp() {
      this.$refs.registerForm.validate();
      if (!this.registerFormValid) return;
      this.registerLoading = true;
      this.loading = true;
      const successful = await this.$store.dispatch('registerWithEmailAndPassword', {
        acceptEula: this.neededEULA ? this.neededEULA : undefined,
        globalUniqueIdentifier: this.getSenator.globalUniqueIdentifier,
        email: this.registerEmail,
        password: this.registerPassword,
        displayName: this.registerDisplayName,
        passwordRepetition: this.registerPasswordRepetition,
      });
      this.registerPassword = null;
      this.registerPasswordRepetition = null;
      if (!successful) {
        this.$toast.open({ message: 'Registration Unsuccessful!', type: 'error' });
      } else {
        this.$toast.open('Successfully Registered!');
      }
      this.registerLoading = false;
      this.loading = false;
    },

    async reloadServiceProviders() {
      let changedLoading = false;
      if (!this.loading) {
        changedLoading = true;
        this.loading = true;
      }
      this.serviceProviders = await this.$store.dispatch('fetchServiceProviders');
      if (changedLoading) {
        this.loading = false;
      }
    },
    async createAccountAtServiceProvider() {
      this.$refs.createAccountForm.validate();
      if (!this.createAccountFormValid) return;
      this.loading = true;
      if (!this.doesServiceProviderAlreadyExist) {
        const successful = await this.$store.dispatch('createServiceProvider', {
          globalUniqueIdentifier: this.$route.params.targetInstance,
          name: this.targetBackendInstance.name,
        });
        if (!successful) {
          this.$toast.open({ message: 'Could not create Service Provider!', type: 'error' });
          this.loading = false;
          return;
        }
        await this.reloadServiceProviders();
      }

      const successful = await this.$store.dispatch('createAccountAtServiceProvider', {
        serviceProviderId: this.serviceProvider.serviceProviderId,
        displayName: this.createAccountDisplayName ? this.createAccountDisplayName : undefined,
        acceptEula: this.targetInstanceNeedsEula ? this.targetInstanceNeedsEula : undefined,
      });
      this.loading = false;
      if (!successful) {
        this.$toast.open({ message: 'Could not create Account at Service Provider!', type: 'error' });
      } else {
        this.$toast.open({ message: 'Successfully Created Account at Service Provider', type: 'success' });
        await this.reloadServiceProviders();
      }
    },
    async loginAtServiceProvider() {
      if (!this.doesAccountAtServiceProviderAlreadyExist) return;
      this.signInViaSSOLoading = true;
      this.loading = true;
      const successful = await this.$store.dispatch('loginWithIdentityProvider', this.targetBackendInstance.globalUniqueIdentifier);
      this.signInViaSSOLoading = false;
      this.loading = false;
      if (!successful) {
        this.$toast.open({ message: 'Could not Sign in at the Service Provider!', type: 'error' });
      } else {
        this.$toast.open({ message: 'Successfully Signed in!', type: 'success' });
        await this.$router.replace({ name: 'Dashboard', params: { barnId: this.targetBackendInstance.globalUniqueIdentifier } });
      }
    },
    async logout() {
      this.loading = true;
      await this.$store.dispatch('logOutOfInstance', { globalUniqueIdentifier: this.getSenator.globalUniqueIdentifier, skipNavigation: true });
      await this.reloadServiceProviders();
      this.loading = false;
    },
    goToBarnSelection() {
      this.$router.replace({ name: 'SelectBarn' });
    },
  },
};
</script>

<style scoped>

</style>
