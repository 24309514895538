<template>

  <v-card dark class="my-4" elevation="10" style="position: relative;">
    <v-card-title>
      {{incident.state === "hidden" ? $t("barnDocumentation.hidden")+": " : ""}}{{$t("barnDocumentation.specifications."+payload.specification.type)}}
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>list_alt</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Classification
            </v-list-item-title>
            <v-list-item-subtitle>
              {{$t("barnDocumentation.classifications."+payload.classification)}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon>label</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Specification
            </v-list-item-title>
            <v-list-item-subtitle>
              {{$t("barnDocumentation.specifications."+payload.specification.type)}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      <v-list-item v-if="payload.specification.startTimestamp">
        <v-list-item-avatar>
          <v-icon>start</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Start
          </v-list-item-title>
          <v-list-item-subtitle>
            {{humanReadableTimestamp(payload.specification.startTimestamp)}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

        <v-list-item v-if="payload.specification.endTimestamp">
          <v-list-item-avatar>
            <v-icon>start</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              End
            </v-list-item-title>
            <v-list-item-subtitle>
              {{humanReadableTimestamp(payload.specification.endTimestamp)}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="payload.specification.timestamp">
          <v-list-item-avatar>
            <v-icon>history</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Timestamp
            </v-list-item-title>
            <v-list-item-subtitle>
              {{humanReadableTimestamp(payload.specification.timestamp)}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="payload.specification.location === 'global'">
          <v-list-item-avatar>
            <v-icon>location_on</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Location
            </v-list-item-title>
            <v-list-item-subtitle>
              Global
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="payload.specification.location.cameraId">
          <v-list-item-avatar>
            <v-icon>videocam</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Cameras
            </v-list-item-title>
            <v-list-item-subtitle>
              {{payload.specification.location.cameraId}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="payload.specification.location.regionSpecification">
          <v-list-item-avatar>
            <v-icon>location_on</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Regions
            </v-list-item-title>
            <v-list-item-subtitle>
              {{payload.specification.location.regionSpecification}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="incident.state === 'hidden'">
          <v-list-item-avatar>
            <v-icon>question_mark</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Reason for being hidden
            </v-list-item-title>
            <v-list-item-subtitle>
              {{incident.messagePayload.reason}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="payload.description">
          <v-list-item-avatar><v-icon>description</v-icon></v-list-item-avatar>
          <v-list-item-content align-start>
            <v-list-item-title>Description</v-list-item-title>
            <v-card flat class="ma-0 pa-0">
              <v-card-text class="ma-0 pa-0">
                <span v-html="sanitizedHTML(payload.description)"></span>              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-end>
        <v-dialog
          v-if="showHideButton"
          v-model="hideDialog"
          width="auto">
          <template v-slot:activator="{on}">
            <v-btn rounded v-on="on"><v-icon class="mr-2">visibility_off</v-icon>Hide Record</v-btn>
          </template>
          <v-card dark>
            <v-card-title>Hide Barn Documentation Record</v-card-title>
            <v-card-text>
              <v-textarea outlined solo v-model="hideReason" label="Reason"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn v-on:click="hide">Hide</v-btn>
            </v-card-actions>
          </v-card>

        </v-dialog>
        <v-btn rounded v-if="showUnHideButton" v-on:click="show"><v-icon class="mr-2">visibility</v-icon>Un-Hide Record</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BarnDocumentationIncident',
  data() {
    return {
      firstEvent: null,
      hideReason: '',
      hideDialog: false,
    };
  },
  props: {
    events: Array,
    incident: Object,
    enableMoreButton: Boolean,
    enableDeleteIncidentButton: Boolean,
    enableCloseIncidentButton: Boolean,
    enableTriggerIncidentButton: Boolean,
  },
  computed: {
    ...mapGetters([
      'hasAllPermissions',
      'hasAnyPermission',
    ]),
    payload() {
      if (this.incident.state !== 'hidden') return this.incident.messagePayload;
      if (this.firstEvent) return this.firstEvent.payload;
      return {};
    },
    showHideButton() {
      if (this.hasAnyPermission({ permissions: ['incidents.barnDocumentation.manage', 'incidents.manage.advanced', 'incidents.manage'] })) {
        return this.incident.state === 'created';
      }
      return false;
    },
    showUnHideButton() {
      if (this.hasAnyPermission({ permissions: ['incidents.barnDocumentation.manage', 'incidents.manage.advanced', 'incidents.manage'] })) {
        return this.incident.state === 'hidden';
      }
      return false;
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'incident.state': {
      async handler() {
        if (this.incident.state !== 'hidden') return;
        [this.firstEvent] = await this.$store.dispatch('loadFirstEventsForIncident', { incidentId: this.incident.incidentId, eventCount: 1 });
      },
      deep: true,
      immediate: true,
      eager: true,
    },
  },
  methods: {
    async hide() {
      await this.$store.dispatch('triggerIncidentEvent', {
        identifier: this.incident.identifier,
        type: 'barnDocumentation',
        trigger: 'hide',
        payload: {
          reason: this.hideReason,
        },

      });
      this.hideReason = '';
      this.hideDialog = false;
      this.$emit('reload');
    },
    async show() {
      await this.$store.dispatch('triggerIncidentEvent', {
        identifier: this.incident.identifier,
        type: 'barnDocumentation',
        trigger: 'create',
        payload: this.firstEvent.payload,
      });
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>

</style>
