<template>
  <v-container>
    <div
      style="z-index: 300"
      class="fixed inset-0 w-full bg overflow-y-auto p-4 sm:p-0 md:pt-20 sm:pt-20 pt-28"
    >
      <div
        :class="size"
        class="mx-auto w-full mask rounded-lg relative light sm:p-6 p-2"
      >
        <svg

          v-if="showCancel"
          @click="close"
          xmlns="http://www.w3.org/2000/svg"
          id="Capa_1"
          enable-background="new 0 0 386.667 386.667"
          class="w-4 h-4 absolute right-0 -mt-16 text-white fill-current cursor-pointer"
          viewBox="0 0 386.667 386.667"
        >
          <path
            d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z"
          />
        </svg>
        <v-container class="w-full h-auto">
          <slot />
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    cancel: {
      default: true,
    },
    size: {
      default: 'max-w-xl',
    },
    showCancel: {
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    document.body.style.setProperty('overflow', 'hidden');
  },
  unmounted() {
    document.body.style.removeProperty('overflow');
  },
};
</script>

<style>
.mask {
  background: #252936 !important;
}
.light {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.bg {
  background: rgba(51, 51, 51, 0.965);
}
</style>
