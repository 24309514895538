import Api from '../../config/Api/API';

const logger = require('@/Logger');

const state = {
  barnState: {},
};

const getters = {
  hasBarnState: (state) => (barnId) => Object.keys(state.barnState).includes(barnId),
  getBarnState: (state) => (barnId) => (state.barnState[barnId]),
  getLatestBarnStateEvent: (state) => (barnId) => (state.barnState[barnId].latestEvent),
  getLastUpdatedStatusTimestamp: (state) => (barnId) => (state.barnState[barnId].lastUpdated),
};
const mutations = {
  setBarnState(state, { barnId, record }) {
    this._vm.$set(state.barnState, barnId, record);
  },
};
const actions = {
  async loadIncidentList(_, {
    query, order = false, projection = undefined,
  }) {
    try {
      const payload = { query, projection };
      if (order !== false) payload.order = order;
      const result = await Api.post({ path: 'api/incidents/query', payload });
      if (!result.successful) return false;
      return result.response.data;
    } catch (e) {
      logger.log('ERROR', `Could not Load IncidentList with Query ${JSON.stringify(query)} because of ${e.stack}`);
    }
    return undefined;
  },

  async getIncidentSchema({ rootGetters }, { globalUniqueIdentifier = undefined, incidentType, simplified = true }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.get({ path: `api/incidents/handlers/${incidentType}/${simplified}`, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return false;
    return response.response.data;
  },

  async fetchDeadAnimalIncidents() {
    const query = [
      { key: 'type', operator: '==', value: 'deadAnimal' },
      { key: 'state', operator: '==', value: 'deadAnimalExists' },
      { key: 'isPublicOpen', operator: '==', value: true },
    ];
    const endpoint = 'api/incidents/query';
    const response = await Api.post({ path: endpoint, payload: { query } });
    return response.response.data;
  },

  async fetchBarnStateIncident({
    commit, rootGetters, getters,
  }, { force = false, barnId = undefined }) {
    try {
      let barnIdToUse = barnId;
      if (barnIdToUse === undefined) { barnIdToUse = rootGetters.getSelectedBarnId; }
      if (force !== true && getters.hasBarnState(barnIdToUse) && getters.getLastUpdatedStatusTimestamp(barnIdToUse) > Date.now() - 1000 * 60 * 10) return;
      const incidentQuery = [
        { key: 'type', operator: '==', value: 'barnState' },
      ];
      const incidentEndpoint = 'api/incidents/queryOne';
      const incidentResponse = await Api.post({ globalUniqueIdentifier: barnIdToUse, path: incidentEndpoint, payload: { query: incidentQuery } });
      if (!incidentResponse.successful) return;
      const incident = incidentResponse.response.data;
      const eventQuery = [{ key: 'incidentId', operator: '==', value: incident.incidentId }];
      const eventEndpoint = 'api/incidentEvents/queryOne';
      const eventOrder = { eventTimestamp: 'desc' };
      const eventResponse = await Api.post({ globalUniqueIdentifier: barnIdToUse, path: eventEndpoint, payload: { query: eventQuery, order: eventOrder } });
      if (!eventResponse.successful) return;
      const latestEvent = eventResponse.response.data;
      commit('setBarnState', {
        barnId: barnIdToUse,
        record: {
          state: incident.state,
          latestEvent,
          lastUpdated: Date.now(),
          incident,
        },
      });
    } catch (e) { logger.log('ERROR', `Could not load BarnState Incident of BarnId ${barnId} because of ${e.stack}`); }
  },

  async closeIncident(_, { incidentId }) {
    const endpoint = `api/incidents/${incidentId}`;
    const response = await Api.put({ path: endpoint });
    return response.successful;
  },

  async markIncidentAsFalsePositive(_, { identifier, messagePayload }) {
    const endpoint = 'api/incidents/';
    const response = await Api.post({
      path: endpoint,
      payload: {
        incidentData: {
          type: 'deadAnimal',
          identifier,
          trigger: 'detectedDeadAnimal',
          payload: { cameraName: messagePayload.cameraName, timestamp: messagePayload.timestamp, detection: messagePayload.lastDetection },
          optionalOverrides: {
            isPublicOpen: false,
          },
        },
      },
    });
    return response.successful;
  },

  async triggerIncidentEvent(_, {
    identifier, type, trigger, payload,
  }) {
    const endpoint = 'api/incidents/';
    const response = await Api.post({
      path: endpoint,
      payload: {
        incidentData: {
          type,
          identifier,
          trigger,
          payload,
        },
      },
    });
    return response.successful;
  },
  async addBarnInterventionIncident(_, { record }) {
    const endpoint = 'api/incidents';
    const response = await Api.post({
      path: endpoint,
      payload: {
        incidentData: {
          identifier: {},
          type: 'barnIntervention',
          trigger: 'issueDetected',
          payload: record,
        },
      },
    });
    return response.successful;
  },

  async loadIncidentsForSpecificEpoch({ dispatch }, { epochOffset = 0, incidentType }) {
    const { startTimestamp, endTimestamp } = await dispatch('getBoundaryTimestampsForSpecificEpoch', { epochOffset });
    const endpoint = 'api/incidents/query';
    const querySpec = [

      { key: 'type', operator: '==', value: incidentType },
      { key: 'openedAt', operator: '>=', value: startTimestamp },
      { key: 'openedAt', operator: '<=', value: endTimestamp },
    ];
    const response = await Api.post({ path: endpoint, payload: { query: querySpec, order: { openedAt: 'desc' } } });
    return response.response.data;
  },

  async getBoundaryTimestampsForSpecificEpoch({ dispatch, getters, rootGetters }, { epochOffset = 0 }) {
    await dispatch('fetchBarnStateIncident', {});
    const barnState = getters.getBarnState(rootGetters.getSelectedBarnId);
    let startTimestamp;
    let endTimestamp;
    let eventCountToLoad = epochOffset + 1;
    if (barnState.state !== 'barnFull') {
      eventCountToLoad += 1;
    }
    if (eventCountToLoad > 1) {
      const lastEvents = await dispatch('loadLastEventsForIncident', { incidentId: barnState.incident.incidentId, eventCount: eventCountToLoad });
      if (lastEvents.length !== eventCountToLoad) return [];
      startTimestamp = lastEvents[lastEvents.length - 2].eventTimestamp;
      endTimestamp = lastEvents[lastEvents.length - 1].eventTimestamp;
    } else {
      startTimestamp = barnState.latestEvent.eventTimestamp;
      endTimestamp = Date.now();
    }
    return { startTimestamp, endTimestamp };
  },

  async getCurrentDayOfEpoch({ dispatch }) {
    const { startTimestamp } = await dispatch('getBoundaryTimestampsForSpecificEpoch', { epochOffset: 0 });
    return Math.floor((Date.now() - startTimestamp) / 1000 / 60 / 60 / 24);
  },
  async loadLatestBarnInterventionIncidents({ rootGetters }, { count = 1000, globalUniqueIdentifier = undefined }) {
    const endpoint = 'api/incidents/query';
    const querySpec = [
      { key: 'type', operator: '==', value: 'barnIntervention' },
    ];
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.post({ path: endpoint, payload: { query: querySpec, limit: count, order: { openedAt: 'desc' } }, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },

  async loadOpenBarnInterventionIncidents({ rootGetters }, { count = 1000, globalUniqueIdentifier = undefined }) {
    const endpoint = 'api/incidents/query';
    const querySpec = [
      { key: 'type', operator: '==', value: 'barnIntervention' },
      { key: 'state', operator: '!=', value: 'closed' },
    ];
    const globalUniqueIdentifierToUse = globalUniqueIdentifier || rootGetters.getSelectedBarnId;
    const response = await Api.post({ path: endpoint, payload: { query: querySpec, limit: count, order: { openedAt: 'desc' } }, globalUniqueIdentifier: globalUniqueIdentifierToUse });
    if (!response.successful) return [];
    return response.response.data;
  },

  async loadGlobalOpenBarnInterventionIncidents({ dispatch, rootGetters }) {
    const barns = rootGetters.getBarns;
    const interventions = [];
    const promises = [];
    for (const globalUniqueIdentifier of Object.keys(barns)) {
      promises.push(new Promise((resolve, reject) => {
        dispatch('loadOpenBarnInterventionIncidents', { globalUniqueIdentifier }).then((barnInterventions) => {
          barnInterventions.forEach((intervention) => {
            interventions.push({
              globalUniqueIdentifier, barnName: barns[globalUniqueIdentifier].name, realWorldName: barns[globalUniqueIdentifier].realWorldName, ...intervention,
            });
          });
          resolve();
        }).catch(reject);
      }));
    }
    await Promise.allSettled(promises);
    return interventions;
  },

  async loadGlobalLatestBarnInterventionIncidents({ dispatch, rootGetters }) {
    const barns = rootGetters.getBarns;
    const interventions = [];
    const promises = [];
    for (const globalUniqueIdentifier of Object.keys(barns)) {
      promises.push(new Promise((resolve, reject) => {
        dispatch('loadLatestBarnInterventionIncidents', { globalUniqueIdentifier }).then((barnInterventions) => {
          barnInterventions.forEach((intervention) => {
            interventions.push({
              globalUniqueIdentifier, barnName: barns[globalUniqueIdentifier].name, realWorldName: barns[globalUniqueIdentifier].realWorldName, ...intervention,
            });
          });
          resolve();
        }).catch(reject);
      }));
    }
    await Promise.allSettled(promises);
    return interventions;
  },

  async deleteIncident(_, { incidentId }) {
    const endpoint = `api/incidents/${incidentId}`;
    const response = await Api.delete({ path: endpoint });
    return response.response.data;
  },

  async deleteIncidentEvent(_, { incidentEventId }) {
    const endpoint = `api/incidentEvents/${incidentEventId}`;
    const response = await Api.delete({ path: endpoint });
    return response.response.data;
  },

  async loadAllEventsForIncidentList(_, { incidentIds }) {
    const chunksize = 10;
    let events = [];
    for (let i = 0; i < incidentIds.length; i += chunksize) {
      const querySpec = [{ key: 'incidentId', operator: 'in', value: incidentIds.slice(i, i + chunksize) }];
      const payload = { query: querySpec, order: { eventTimestamp: 'asc' } };
      // eslint-disable-next-line no-await-in-loop
      const response = await Api.post({ path: 'api/incidentEvents/query', payload });
      if (!response.successful) return null;
      events = events.concat(response.response.data);
    }
    return events;
  },
  async loadAllEventsForMultiInstanceIncidentList(_, { incidents }) {
    const events = [];
    const promises = [];
    for (const incident of incidents) {
      const querySpec = [{ key: 'incidentId', operator: '==', value: incident.incidentId }];
      const payload = { query: querySpec, order: { eventTimestamp: 'asc' } };
      promises.push(new Promise((resolve) => {
        Api.post({ path: 'api/incidentEvents/query', payload, globalUniqueIdentifier: incident.globalUniqueIdentifier }).then((response) => {
          if (response.successful) {
            response.response.data.forEach((event) => {
              events.push({
                globalUniqueIdentifier: incident.globalUniqueIdentifier, barnName: incident.barnName, realWorldName: incident.realWorldName, ...event,
              });
            });
          }
          resolve();
        });
      }));
    }
    await Promise.allSettled(promises);
    return events;
  },
  async loadLastEventsForIncident(_, { incidentId, eventCount = 0 }) {
    const endpoint = 'api/incidentEvents/query';
    const querySpec = [
      { key: 'incidentId', operator: '==', value: incidentId },
    ];
    const payload = { query: querySpec, order: { eventTimestamp: 'desc' } };
    if (eventCount > 0) {
      payload.limit = eventCount;
    }
    const response = await Api.post({ path: endpoint, payload });
    if (!response.successful) return null;
    return response.response.data;
  },

  async loadFirstEventsForIncident(_, { incidentId, eventCount = 0 }) {
    const endpoint = 'api/incidentEvents/query';
    const querySpec = [
      { key: 'incidentId', operator: '==', value: incidentId },
    ];
    const payload = { query: querySpec, order: { eventTimestamp: 'asc' } };
    if (eventCount > 0) {
      payload.limit = eventCount;
    }
    const response = await Api.post({ path: endpoint, payload });
    if (!response.successful) return null;
    return response.response.data;
  },

  async getIncident(_, { incidentId }) {
    const endpoint = 'api/incidents/queryOne';
    const querySpec = [
      { key: 'incidentId', operator: '==', value: incidentId },
    ];
    const response = await Api.post({ path: endpoint, payload: { query: querySpec } });
    if (!response.successful) return null;
    return response.response.data;
  },

  async loadSpeedWatchdog({ getters }, globalUniqueIdentifier = undefined) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier === undefined ? getters.getSelectedBarnId : globalUniqueIdentifier;

    const response = await Api.post({
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      path: 'api/watchdogs/queryOne',
      payload: { query: [{ key: 'breedName', operator: '==', value: 'speed' }] },
    });
    if (response.successful) return response.response.data;
    return false;
  },
  async createSpeedWatchdog({ getters }, {
    globalUniqueIdentifier = undefined, speedThreshold, dailyResetOffsetHours,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier === undefined ? getters.getSelectedBarnId : globalUniqueIdentifier;

    const response = await Api.post({
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      path: 'api/watchdogs/schedule/speed',
      payload: {
        watchdog: {
          name: 'Speed Watchdog',
          schedule: '*/10 * * * *',
          parameters: {
            speedThreshold,
            dailyResetOffsetHours,
            maxDailyIncreasedSeverityWarnings: 3,
          },
        },
      },
    });
    return response.successful;
  },
  async updateSpeedWatchdog({ getters }, {
    globalUniqueIdentifier = undefined,
    watchdogId,
    speedThreshold,
    dailyResetOffsetHours,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier === undefined ? getters.getSelectedBarnId : globalUniqueIdentifier;

    const response = await Api.patch({
      globalUniqueIdentifier: globalUniqueIdentifierToUse,
      path: `api/watchdogs/${watchdogId}`,
      payload: {
        updates: {
          schedule: '*/10 * * * *',
          parameters: {
            speedThreshold,
            dailyResetOffsetHours,
            maxDailyIncreasedSeverityWarnings: 3,
          },
        },
      },
    });
    return response.successful;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
