import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import backend from '@/store/backend/backend';
import createMultiTabState from 'vuex-multi-tab-state';
import barn from './barn/barn';
import camera from './camera/camera';
import graph from './graph/graph';
import artifacts from './artifacts/artifacts';
import miscellaneous from './miscellaneous/miscellaneous';
import regions from './regions/regions';
import data from './data/data';
import canvas from './camera/canvas';
import incident from './incidents/incident';
import senator from './senator/senator';
import authorization from './authorization/authorization';
import serviceAccounts from './serviceAccounts/serviceAccounts';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    data,
    barn,
    camera,
    graph,
    miscellaneous,
    regions,
    artifacts,
    canvas,
    incident,
    backend,
    senator,
    authorization,
    serviceAccounts,
  },
  plugins: [
    createPersistedState({
      paths: [
        'senator',
        'backend.backendInstances',
        'barn.authRecords',
        'barn.barns',
        'barn.checkEula',
        'miscellaneous',
      ],
    }),
    createMultiTabState(
      { statesPaths: ['senator', 'backend.backendInstances', 'miscellaneous', 'barn.barns', 'barn.authRecords'] },
    ),
  ],
});

export default store;
