function calculateDurationFromLiteral(durationLiteral) {
  // define an object to convert the literal time unit to millisecond
  const convertToMillisecond = {
    w: 7 * 24 * 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000,
    h: 60 * 60 * 1000,
    m: 60 * 1000,
    s: 1 * 1000,
    ms: 1,
  };
  const timeRegex = /w|d|h|m|s|ms/ig;
  // if the unit of duration word match any unit in the timeRegex, calculate how many Millisecond the duration contains
  const durationInMillisecond = parseInt(durationLiteral.match(/[0-9]/g).join('')) * convertToMillisecond[durationLiteral.match(timeRegex)[0]];
  return durationInMillisecond;
}

function heatmap(artifact, state, getters, rootState, rootGetters) {
  const enrichedRecord = {
    camera: rootGetters.cameraName(artifact.data.cameraId),
    labels: artifact.data.labels.toString(),
    gridsize: artifact.data.settings.gridsize,
  };
  let startTime = artifact.data.time.start;
  let duration;
  let endTime = artifact.timestamp;
  if (Object.keys(artifact.data.time).includes('duration') && Object.keys(artifact.data.time).includes('start')) endTime = artifact.time.start + calculateDurationFromLiteral(artifact.data.time.duration);
  if (!Object.keys(artifact.data.time).includes('duration')) duration = endTime - artifact.data.time.start; else duration = calculateDurationFromLiteral(artifact.data.time.duration);
  if (!Object.keys(artifact.data.time).includes('start')) startTime = endTime - duration;
  enrichedRecord.startTime = startTime;
  enrichedRecord.duration = duration;
  enrichedRecord.endTime = endTime;
  return { ...artifact, _enriched: enrichedRecord };
}

function longTimeExposure(artifact, state, getters, rootState, rootGetters) {
  const enrichedRecord = {
    camera: rootGetters.cameraName(artifact.data.cameraId),
    timespan: artifact.data.durationSeconds * 1000,
    frameInterval: artifact.data.frameIntervalSeconds * 1000,
    timeOffset: artifact.data.offsetSeconds * 1000,
  };
  enrichedRecord.endTime = artifact.timestamp - enrichedRecord.timeOffset;
  enrichedRecord.startTime = enrichedRecord.endTime - enrichedRecord.timespan;
  return { ...artifact, _enriched: enrichedRecord };
}

module.exports = { heatmap, longTimeExposure };
