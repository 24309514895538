<template>
  <v-container dark fluid>
    <v-card dark class="ma-0 pa-0">
      <v-card-text dark>
        <v-toolbar class="mx-0 px-0">
          <v-tabs v-model="tabs" class="mx-0 px-0">
            <v-tab >Latest</v-tab>
            <v-tab >Open</v-tab>
          </v-tabs>

        </v-toolbar>
        <v-tabs-items v-model="tabs" dark>
          <v-tab-item>
            <InterventionList :global-interventions="true" :type-options="typeOptions" :incidents="latestBarnInterventionIncidents" v-on:reload="reload"></InterventionList>
          </v-tab-item>
          <v-tab-item>
            <InterventionList :global-interventions="true" :type-options="typeOptions" :incidents="openBarnInterventionIncidents" v-on:reload="reload"></InterventionList>
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import InterventionList from '@/views/Dashboard/Summary/InterventionList.vue';

export default {
  name: 'GlobalInterventions',
  components: { InterventionList },
  data() {
    return {
      tabs: null,
      openBarnInterventionIncidents: null,
      barnInterventionIncidentsForCurrentEpoch: null,
      latestBarnInterventionIncidents: null,
      severities: ['none', 'low', 'middle', 'high'],
      severityLabels: ['None', 'Low', 'Middle', 'High'],
      severityIndex: 2,
      barnInterventionIncidents: [],
      typeOptions: [
        { value: 'climate', text: 'Climate' },
        { value: 'moisture', text: 'Moisture' },
        { value: 'technical', text: 'Technical' },
        { value: 'stress', text: 'Stress' },
        { value: 'health', text: 'Health' },
        { value: 'other', text: 'Other' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'hasAllPermissions',

    ]),

    severity() {
      return this.severities[this.severityIndex];
    },
    severityColor() {
      if (this.severityIndex === 3) return 'red';
      if (this.severityIndex === 2) return 'orange';
      if (this.severityIndex === 1) return 'green';
      if (this.severityIndex === 0) return 'blue';
      return 'white';
    },
  },
  async mounted() {
    await this.reload();
  },
  methods: {
    async reload() {
      await this.loadCurrentlyOpenBarnInterventionIncidents();
      await this.loadLatestBarnInterventionIncidents();
    },
    async loadCurrentlyOpenBarnInterventionIncidents() {
      this.openBarnInterventionIncidents = await this.$store.dispatch('loadGlobalOpenBarnInterventionIncidents', {});
    },

    async loadLatestBarnInterventionIncidents() {
      this.latestBarnInterventionIncidents = await this.$store.dispatch('loadGlobalLatestBarnInterventionIncidents', {});
    },
  },
};
</script>
