import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '../../store/index';

const logger = require('../../Logger');

const backendRequestQueues = {};

async function triggerAccessTokenRefresh(globalUniqueIdentifier) {
  const successful = await store.dispatch('fetchAccessToken', { globalUniqueIdentifier });
  if (successful === true) {
    const accessToken = store.getters.getAccessToken(globalUniqueIdentifier);
    const needToRetry = backendRequestQueues[globalUniqueIdentifier];
    backendRequestQueues[globalUniqueIdentifier] = [];
    needToRetry.forEach((entry) => {
      entry
        .handler(accessToken)
        .then((r) => entry.resolve(r))
        .catch((e) => entry.reject(e));
    });
  } else {
    const needToRetry = backendRequestQueues[globalUniqueIdentifier];
    backendRequestQueues[globalUniqueIdentifier] = [];
    needToRetry.forEach((entry) => {
      entry.reject('No Auth');
    });
  }
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_CANCELED') return Promise.reject(error);
    const pathName = new URL(error.config.url).pathname;
    const globalUniqueIdentifier = error.config._globalUniqueIdentifier;

    logger.log('DEBUG', `Request to Path ${pathName} of GUID ${globalUniqueIdentifier} failed with Error Code ${error.response.status} and message ${error.message}`);

    if (error.response.status === 403 && (pathName === '/api/login/refresh' || pathName === `/gw/${globalUniqueIdentifier}/api/login/refresh`)) {
      // couldn't load new access token. because the refresh token is either broken, invalid or expired
      logger.log('DEBUG', `Invalidating Refresh Token for Instance ${globalUniqueIdentifier}`);
      return store.dispatch('logOutOfInstance', { globalUniqueIdentifier });
    }
    if (error.response.status === 451 && (pathName === '/api/login/refresh' || pathName === `/gw/${globalUniqueIdentifier}/api/login/refresh`)) {
      // couldn't load new access token because the latest EULA version is missing.
    }

    if (error.response.status === 401 && error.config._retry !== true) {
      // not authenticated, the access token is probably expired. Create a new one!
      if (!Object.keys(backendRequestQueues).includes(globalUniqueIdentifier)) backendRequestQueues[globalUniqueIdentifier] = [];
      const retryPromise = new Promise((resolve, reject) => {
        backendRequestQueues[globalUniqueIdentifier].push({
          handler: (newAuthenticationToken) => {
            const request = error.config;
            request._retry = true;
            request.headers.Authorization = `Bearer ${newAuthenticationToken}`;
            return axios(request);
          },
          resolve,
          reject,
        });
      });
      if (backendRequestQueues[globalUniqueIdentifier].length === 1) {
        triggerAccessTokenRefresh(globalUniqueIdentifier).then(() => {});
      }
      return retryPromise;
    }

    return Promise.reject(error); // Different Error, Handling up to caller
  },
);
axios.interceptors.request.use((config) => {
  if (config._requireAuth === true) {
    const authenticationToken = store.getters.getAccessToken(config._globalUniqueIdentifier);
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authenticationToken}`;
  }
  return config;
});
class Api {
  static async request({
    globalUniqueIdentifier,
    path,
    payload,
    skipAuthentication,
    method,
    responseType,
    skipGlobalUniqueIdentifier = false,
    url,
    params,
    signal = null,
    onDownloadProgress = null,
    acceptHeader = null,
  }) {
    const globalUniqueIdentifierToUse = globalUniqueIdentifier === undefined ? store.getters.getSelectedBarnId : globalUniqueIdentifier;
    const baseUrl = store.getters.getBackendInstanceUrlToUse(globalUniqueIdentifierToUse);
    const urlToUse = url === undefined ? baseUrl + path : url;
    if (!urlToUse) { return { successful: false }; }
    const config = {
      url: urlToUse,
      _requireAuth: true,
      method,
      _globalUniqueIdentifier: skipGlobalUniqueIdentifier === false ? globalUniqueIdentifierToUse : undefined,
      responseType: 'json',
      params,
    };
    if (acceptHeader !== null) { config.headers = { Accept: acceptHeader }; }
    if (signal !== null) config.signal = signal;
    if (onDownloadProgress !== null) config.onDownloadProgress = onDownloadProgress;
    if (responseType !== undefined) config.responseType = responseType;
    if (payload !== undefined) config.data = payload;
    if (skipAuthentication === true) config._requireAuth = false;
    try {
      const response = await axios.request(config);
      return { successful: true, response };
    } catch (error) {
      return { successful: false, error };
    }
  }

  static async get(requestConfig) {
    return Api.request({ method: 'GET', ...requestConfig });
  }

  static async post(requestConfig) {
    return Api.request({ method: 'POST', ...requestConfig });
  }

  static async put(requestConfig) {
    return Api.request({ method: 'PUT', ...requestConfig });
  }

  static async patch(requestConfig) {
    return Api.request({ method: 'PATCH', ...requestConfig });
  }

  static async delete(requestConfig) {
    return Api.request({ method: 'DELETE', ...requestConfig });
  }

  static async head(requestConfig) {
    return Api.request({ method: 'HEAD', ...requestConfig });
  }
}

export default Api;
